import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTimeMinAndTimeMax } from "./calendarUtils";
import moment from "moment";
import { googleServerUrl } from "../../utils";
import axios from "axios";
import { setToastVisible } from "../appSlice";
import {
  getAppleCalendarEventsFromDB,
  updateAppleEventInIndexedDB,
  deleteAppleEventFromIndexedDB,
} from "./calendarIndexDBUtils";
import { openCalendarDB } from "./indexDbSchema";

export const fetchAppleCalendarEvents = createAsyncThunk(
  "calendar/fetchAppleCalendarEvents",
  async (signal, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;
    const calendarDate = getState().tasks.calendarDate;

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    try {
      var systemTimezone = moment.tz.guess();

      if (!systemTimezone) {
        systemTimezone = "America/Chicago";
      }

      const response = await axios.get(
        `${googleServerUrl}/getAppleCalendarEvents`,
        {
          params: {
            userId: getState().app.uid,
            startDate: timeMin,
            endDate: timeMax,
            systemTimezone: systemTimezone,
            active_calendars: activeCalendarsJson,
          },
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        }
      );

      if (signal.aborted) throw new Error("Cancelled");

      const newEvents = response.data.map((event) => ({
        id: event.id,
        title: event.title,
        description: event.description,
        start: event.startDate,
        end: event.endDate,
        calendar: event.calendar,
        location: event.location,
        attendees: event.attendees,
        etag: event.etag,
        calendarAccount: event.calendarAccount,
        extendedProps: {
          type: "apple",
          url: event.url,
          data: event.data,
          recurring: event.recurring,
        },
      }));

      try {
        // Store the new events in IndexedDB
        const db = await openCalendarDB();
        const tx = db.transaction("appleCalendarEvents", "readwrite");
        const store = tx.objectStore("appleCalendarEvents");

        const existingEvents = await getAppleCalendarEventsFromDB(
          timeMin,
          timeMax,
          active_calendars,
          tx,
          store
        );

        const existingEventIds = new Set(
          existingEvents.map(
            (e) => `/event/${e.calendarAccount}/${e.calendar}/${e.id}`
          )
        );

        for (const existingEvent of existingEvents) {
          if (!newEvents.find((e) => e.id === existingEvent.id)) {
            await store.delete(
              `/event/${existingEvent.calendarAccount}/${existingEvent.calendar}/${existingEvent.id}`
            );
          }
        }

        for (const event of newEvents) {
          const compositeKey = `/event/${event.calendarAccount}/${event.calendar}/${event.id}`;
          await store.put(
            { ...event, last_accessed: new Date().toISOString() },
            compositeKey
          );
        }

        await tx.done;
      } catch (error) {
        console.error(
          `Error storing Google Calendar events in IndexedDB:`,
          error
        );

        dispatch(
          setToastVisible({
            toastType: "error",
            message: `Something went wrong storing Google Calendar events in IndexedDB. Please contact support ${error}`,
          })
        );
      }

      const newAppleEvents = newEvents.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      return fulfillWithValue(newAppleEvents);
    } catch (error) {
      if (axios.isCancel(error) || error.message === "Cancelled") {
        throw new Error("Cancelled");
      }
      console.log(error);
      dispatch(
        setToastVisible({
          toastType: "error",
          message:
            "Something went wrong talking to Apple calendar. Please contact support",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateAppleCalendarEvent = createAsyncThunk(
  "calendar/updateAppleCalendarEvent",
  async (
    { appleEvent, newData },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    try {
      var systemTimezone = moment.tz.guess();

      if (!systemTimezone) {
        systemTimezone = "America/Chicago";
      }

      const response = await axios.post(
        `${googleServerUrl}/updateAppleCalendarEvent`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            data: appleEvent.extendedProps.data,
            url: appleEvent.extendedProps.url,
            etag: appleEvent.etag,
            start: newData.start,
            end: newData.end,
            calendarUser: appleEvent.calendarAccount,
            systemTimezone: systemTimezone,
          },
        }
      );

      if (response.data) {
        const updatedEvent = {
          ...appleEvent,
          ...newData,
          start: newData.start,
          end: newData.end,
        };

        // Update the event in IndexedDB
        await updateAppleEventInIndexedDB(
          appleEvent.id,
          appleEvent.calendar,
          appleEvent.calendarAccount,
          updatedEvent
        );

        return fulfillWithValue({
          newEvent: updatedEvent,
        });
      }
      throw new Error("Failed to update event");
    } catch (error) {
      console.log(error);
      return rejectWithValue(appleEvent);
    }
  }
);

export const deleteAppleCalendarEvent = createAsyncThunk(
  "calendar/deleteAppleCalendarEvent",
  async (
    { appleEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    try {
      const response = await axios.post(
        `${googleServerUrl}/deleteAppleCalendarEvent`,
        appleEvent,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            url: appleEvent.extendedProps.url,
            etag: appleEvent.etag,
            calendarUser: appleEvent.calendarAccount,
          },
        }
      );

      console.log("appleEvent", appleEvent);
      if (response.status === 200) {
        // Delete the event from IndexedDB
        await deleteAppleEventFromIndexedDB(
          appleEvent.id,
          appleEvent.calendar,
          appleEvent.calendarAccount
        );
        return fulfillWithValue(appleEvent.id);
      }
      throw new Error("Failed to delete event");
    } catch (error) {
      console.log(error);
      dispatch(
        setToastVisible({
          toastType: "error",
          message:
            "Something went wrong talking to iCal servers. Please contact support",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const loadCachedAppleEvents = createAsyncThunk(
  "calendar/loadCachedAppleEvents",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const {
        active_calendars = {},
        active_calendar_type = "week",
        mode = "kanban",
      } = getState().app.currentUser;

      const calendarDate = getState().tasks.calendarDate;
      const { timeMin, timeMax } = getTimeMinAndTimeMax(
        active_calendar_type,
        calendarDate,
        mode
      );

      const cachedEvents = await getAppleCalendarEventsFromDB(
        timeMin,
        timeMax,
        active_calendars
      );

      if (cachedEvents.length > 0) {
        const appleEvents = cachedEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});

        return fulfillWithValue({
          appleEvents: appleEvents,
        });
      } else {
        return fulfillWithValue({});
      }
    } catch (error) {
      console.error("Error loading cached Apple Calendar events:", error);
      return rejectWithValue(error);
    }
  }
);
