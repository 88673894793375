import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { openDB } from "idb";
import { googleServerUrl, outlookServerUrl } from "../utils";
import { setToastVisible } from "./appSlice";
import { updateTask } from "./tasksSlice";
import { auth } from "../firebase";
import {
  convertGoogleCalendarEventToEvent,
  convertGoogleEventsToEvents,
  convertTasksToEvents,
  convertTaskToEvent,
  getTimeMinAndTimeMax,
} from "./calendars/calendarUtils";
import {
  deleteCalendarEvent,
  fetchGoogleCalendarEvents,
  loadCachedGoogleEvents,
  updateGoogleCalendarEvent,
  updateRecurringCalendarEvent,
} from "./calendars/googleCalendarThunks";
import {
  deleteAppleCalendarEvent,
  fetchAppleCalendarEvents,
  loadCachedAppleEvents,
  updateAppleCalendarEvent,
} from "./calendars/appleCalendarThunks";
import {
  deleteOutlookCalendarEvent,
  fetchOutlookCalendarEvents,
  loadCachedOutlookEvents,
  updateOutlookCalendarEvent,
} from "./calendars/outlookCalendarThunks";
import {
  fetchTaskEventsFromTasks,
  updateTaskFromEvent,
} from "./calendars/taskCalendarEventThunks";
import {
  deleteEventFromIndexedDB,
  updateEventInIndexedDB,
  updateOutlookEventInIndexedDB,
} from "./calendars/calendarIndexDBUtils";

const initialState = {
  appleEvents: {},
  googleEvents: {},
  outlookEvents: {},
  taskEvents: {},
  googleEventData: {},
  lastSync: null,
  stagedGoogleEvent: null,
  contextMenuActiveForEvent: null,
  calendarEventEditModalActiveFor: null,
  stagedTaskEvents: {},
};

export const fetchAllCalendarEvents = createAsyncThunk(
  "calendar/fetchAllCalendarEvents",
  async (signal, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const results = {
      googleEvents: null,
      appleEvents: null,
      outlookEvents: null,
    };
    let errorMessages = [];

    try {
      // Get calendar_accounts from currentUser
      const calendar_accounts =
        getState().app.currentUser?.calendar_accounts || {};

      // Get the calendar accounts (which contain types) from the currentUser
      const googleCalendars = Object.values(calendar_accounts).filter(
        (calendar) => calendar.type === "google"
      );

      const appleCalendars = Object.values(calendar_accounts).filter(
        (calendar) => calendar.type === "apple"
      );

      const outlookCalendars = Object.values(calendar_accounts).filter(
        (calendar) => calendar.type === "outlook"
      );

      // Load cached events for all calendar types
      await Promise.all([
        dispatch(loadCachedOutlookEvents()),
        dispatch(loadCachedGoogleEvents()),
        dispatch(loadCachedAppleEvents()),
      ]);

      if (googleCalendars && googleCalendars.length > 0) {
        const googleCalendarEvents = await dispatch(
          fetchGoogleCalendarEvents(signal)
        );
        if (signal.aborted) throw new Error("Cancelled");
        if (fetchGoogleCalendarEvents.rejected.match(googleCalendarEvents)) {
          errorMessages.push("Google calendar fetch failed.");
        } else {
          results.googleEvents = googleCalendarEvents.payload;
        }
      }

      if (appleCalendars && appleCalendars.length > 0) {
        const appleCalendarEvents = await dispatch(
          fetchAppleCalendarEvents(signal)
        );
        if (signal.aborted) throw new Error("Cancelled");
        if (fetchAppleCalendarEvents.rejected.match(appleCalendarEvents)) {
          errorMessages.push("Apple calendar fetch failed.");
        } else {
          results.appleEvents = appleCalendarEvents.payload;
        }
      }

      if (outlookCalendars && outlookCalendars.length > 0) {
        const outlookCalendarEvents = await dispatch(
          fetchOutlookCalendarEvents(signal)
        );
        if (signal.aborted) throw new Error("Cancelled");
        if (fetchOutlookCalendarEvents.rejected.match(outlookCalendarEvents)) {
          errorMessages.push("Outlook calendar fetch failed.");
        } else {
          results.outlookEvents = outlookCalendarEvents.payload;
        }
      }

      if (signal.aborted) throw new Error("Cancelled");

      if (errorMessages.length > 0) {
        dispatch(
          setToastVisible({
            toastType: "error",
            message: errorMessages.join(" "),
          })
        );
      }

      return fulfillWithValue(results);
    } catch (error) {
      if (error.message === "Cancelled") {
        return rejectWithValue("Request was cancelled");
      }
      return rejectWithValue(error);
    }
  }
);

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setCalendarEventEditModalActiveFor: (state, action) => {
      state.calendarEventEditModalActiveFor = action.payload;
    },
    setTaskEvents: (state, action) => {
      state.taskEvents = action.payload;
    },
    clearStagedTaskEvents: (state, action) => {
      console.log("Clearing staged task events");
      state.stagedTaskEvents = {};
    },
    updateTaskEvent: (state, action) => {
      const { id, newEventData } = action.payload;
      state.taskEvents[id] = {
        ...state.taskEvents[id],
        ...newEventData,
      };

      state.stagedTaskEvents[id] = {
        ...state.taskEvents[id],
        ...newEventData,
      };
    },
    updateTaskEventFromTaskChangeData: (state, action) => {
      const { id, newTask, labels } = action.payload;

      state.taskEvents[id] = {
        ...state.taskEvents[id],
        ...convertTaskToEvent(newTask, labels),
      };
    },
    setContextMenuActiveForEvent: (state, action) => {
      state.contextMenuActiveForEvent = action.payload;
    },
    stageGoogleEvent: (state, action) => {
      const { originalCalendarEvent, newGoogleEventData } = action.payload;

      var originalGoogleEvent = state.googleEventData[originalCalendarEvent.id];

      // The oriignal
      state.stagedGoogleEvent = {
        originalCalendarEvent: originalCalendarEvent,
        originalGoogleEvent: originalGoogleEvent,
        newGoogleEventData: newGoogleEventData,
      };

      var newGoogleEvent = {
        ...originalGoogleEvent,
        ...newGoogleEventData,
      };

      // Let's first update the googleEventData object
      state.googleEventData = {
        ...state.googleEventData,
        [originalGoogleEvent.id]: newGoogleEvent,
      };

      // Update the event in the googleEvents object
      const newEvents = {
        [originalCalendarEvent.id]:
          convertGoogleCalendarEventToEvent(newGoogleEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };
    },
    revertStagedGoogleEvent: (state, action) => {
      const { originalCalendarEvent, originalGoogleEvent } =
        state.stagedGoogleEvent;

      // Let's first update the googleEventData object
      state.googleEventData = {
        ...state.googleEventData,
        [originalGoogleEvent.id]: originalGoogleEvent,
      };

      // Update the event in the googleEvents object
      const newEvents = {
        [originalCalendarEvent.id]:
          convertGoogleCalendarEventToEvent(originalGoogleEvent),
      };

      state.googleEvents = {
        ...state.googleEvents,
        ...newEvents,
      };

      state.stagedGoogleEvent = null;
    },
    clearStaging: (state, action) => {
      state.stagedGoogleEvent = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteCalendarEvent.pending, (state, action) => {
        const { googleEvent } = action.meta.arg;

        // Remove the event from the googleEvents object
        const newEvents = { ...state.googleEvents };
        delete newEvents[googleEvent.id];
        state.googleEvents = newEvents;

        // Remove the event from the googleEventData object
        const newEventData = { ...state.googleEventData };
        delete newEventData[googleEvent.id];
        state.googleEventData = newEventData;
      })
      .addCase(deleteCalendarEvent.fulfilled, (state, action) => {
        // Delete the event from IndexedDB
        const { googleEvent } = action.meta.arg;
        deleteEventFromIndexedDB(
          googleEvent.id,
          googleEvent.calendarId,
          googleEvent.accountId
        );
      })
      .addCase(deleteCalendarEvent.rejected, (state, action) => {
        const { googleEvent } = action.meta.arg;

        // Add the event back to the googleEvents object
        const newEvents = {
          [googleEvent.id]: convertGoogleCalendarEventToEvent(googleEvent),
        };
        state.googleEvents = {
          ...state.googleEvents,
          ...newEvents,
        };

        // Add the event back to the googleEventData object
        const newEventData = {
          [googleEvent.id]: googleEvent,
        };
        state.googleEventData = {
          ...state.googleEventData,
          ...newEventData,
        };
      })
      .addCase(deleteOutlookCalendarEvent.pending, (state, action) => {
        const { outlookEvent } = action.meta.arg;

        // Remove the event from the outlookEvents object
        const newEvents = { ...state.outlookEvents };
        delete newEvents[outlookEvent.id];
        state.outlookEvents = newEvents;
      })
      .addCase(deleteOutlookCalendarEvent.rejected, (state, action) => {
        const { outlookEvent } = action.meta.arg;

        // Add the event back to the outlookEvents object
        const newEvents = {
          [outlookEvent.id]: outlookEvent,
        };
        state.outlookEvents = {
          ...state.outlookEvents,
          ...newEvents,
        };
      })
      .addCase(updateTaskFromEvent.pending, (state, action) => {
        const event = action.meta.arg;

        // Let's update the event in the taskEvents object
        const newEvents = {
          [event.id]: event,
        };
        state.taskEvents = {
          ...state.taskEvents,
          ...newEvents,
        };
      })
      .addCase(updateTaskFromEvent.fulfilled, (state, action) => {
        // Nothing to do hopefully
      })
      .addCase(updateTaskFromEvent.rejected, (state, action) => {
        // Ideally we can fill this out to undo the event change if we need
      })
      .addCase(fetchTaskEventsFromTasks.fulfilled, (state, action) => {
        state.taskEvents = action.payload;
      })
      .addCase(loadCachedGoogleEvents.fulfilled, (state, action) => {
        const { googleEvents, googleEventData } = action.payload;

        state.googleEvents = googleEvents;
        state.googleEventData = googleEventData;
      })
      .addCase(fetchAllCalendarEvents.pending, (state, action) => {
        state.lastSync = null;
      })
      .addCase(fetchAllCalendarEvents.fulfilled, (state, action) => {
        const { googleEvents, appleEvents, outlookEvents } = action.payload;

        if (googleEvents) {
          state.googleEvents = googleEvents.newEvents;
          state.googleEventData = googleEvents.newGoogleEventData;
        }

        if (appleEvents) {
          state.appleEvents = appleEvents;
        }

        if (outlookEvents) {
          state.outlookEvents = outlookEvents;
        }
        state.lastSync = new Date();
      })
      .addCase(fetchGoogleCalendarEvents.pending, (state, action) => {
        // Pull from indexedDB getGoogleCalendarEventsFromDB
      })
      .addCase(fetchGoogleCalendarEvents.fulfilled, (state, action) => {
        //
      })
      .addCase(fetchGoogleCalendarEvents.rejected, (state, action) => {
        console.log("Error fetching Google Calendar Events");
        // state.lastSync = new Date();
      })
      .addCase(updateRecurringCalendarEvent.pending, (state, action) => {
        const { eventId, newData } = action.meta.arg;
        const googleEventId = state.googleEventData[eventId].id;
        state.googleEventData = {
          ...state.googleEventData,
          [googleEventId]: {
            ...state.googleEventData[googleEventId],
            ...newData,
          },
        };
        const newEvents = {
          [eventId]: convertGoogleCalendarEventToEvent(
            state.googleEventData[googleEventId]
          ),
        };
        state.googleEvents = {
          ...state.googleEvents,
          ...newEvents,
        };
      })
      .addCase(updateGoogleCalendarEvent.pending, (state, action) => {
        const { eventId, newData } = action.meta.arg;
        const googleEventId = state.googleEventData[eventId].id;
        state.googleEventData = {
          ...state.googleEventData,
          [googleEventId]: {
            ...state.googleEventData[googleEventId],
            ...newData,
          },
        };
        const newEvents = {
          [eventId]: convertGoogleCalendarEventToEvent(
            state.googleEventData[googleEventId]
          ),
        };
        state.googleEvents = {
          ...state.googleEvents,
          ...newEvents,
        };
      })
      .addCase(updateOutlookCalendarEvent.pending, (state, action) => {
        const { eventId, newData } = action.meta.arg;
        const outlookEventId = state.outlookEvents[eventId].id;
        state.outlookEvents = {
          ...state.outlookEvents,
          [outlookEventId]: {
            ...state.outlookEvents[outlookEventId],
            ...newData,
          },
        };
      })
      .addCase(loadCachedAppleEvents.fulfilled, (state, action) => {
        const { appleEvents } = action.payload;
        state.appleEvents = appleEvents;
      })
      .addCase(fetchAppleCalendarEvents.fulfilled, (state, action) => {
        state.appleEvents = action.payload;
      })
      .addCase(updateAppleCalendarEvent.pending, (state, action) => {
        const { appleEvent, newData } = action.meta.arg;
        state.appleEvents = {
          ...state.appleEvents,
          [appleEvent.id]: {
            ...state.appleEvents[appleEvent.id],
            ...newData,
          },
        };
      })
      .addCase(updateAppleCalendarEvent.fulfilled, (state, action) => {
        const { newEvent } = action.payload;
        state.appleEvents[newEvent.id] = newEvent;
      })
      .addCase(updateAppleCalendarEvent.rejected, (state, action) => {
        const originalEvent = action.meta.arg.appleEvent;
        state.appleEvents[originalEvent.id] = originalEvent;
      })
      .addCase(deleteAppleCalendarEvent.pending, (state, action) => {
        const { appleEvent } = action.meta.arg;
        const newEvents = { ...state.appleEvents };
        delete newEvents[appleEvent.id];
        state.appleEvents = newEvents;
      })
      .addCase(deleteAppleCalendarEvent.fulfilled, (state, action) => {
        // The event has already been removed in the pending case, so we don't need to do anything here
      })
      .addCase(deleteAppleCalendarEvent.rejected, (state, action) => {
        const { appleEvent } = action.meta.arg;
        state.appleEvents[appleEvent.id] = appleEvent;
      })
      .addCase(updateRecurringCalendarEvent.fulfilled, (state, action) => {
        state.stagedGoogleEvent = null;
      })
      .addCase(updateRecurringCalendarEvent.rejected, (state, action) => {
        console.log("Error updating Google Calendar Event: ", action);
        const originalEvent = action.payload;
        const googleEventId = state.googleEventData[originalEvent.id].id;
        state.googleEventData = {
          ...state.googleEventData,
          [googleEventId]: originalEvent,
        };
        const newEvents = {
          [originalEvent.id]: convertGoogleCalendarEventToEvent(originalEvent),
        };
        state.googleEvents = {
          ...state.googleEvents,
          ...newEvents,
        };
      })
      .addCase(updateGoogleCalendarEvent.fulfilled, (state, action) => {
        state.stagedGoogleEvent = null;
        const { eventId, newData } = action.meta.arg;
        const event = state.googleEventData[eventId];
        if (event) {
          updateEventInIndexedDB(
            event.id,
            event.calendarId,
            event.accountId,
            newData
          );
        }
      })
      .addCase(updateGoogleCalendarEvent.rejected, (state, action) => {
        console.log("Error updating Google Calendar Event: ", action);
        const originalEvent = action.payload;
        const googleEventId = state.googleEventData[originalEvent.id].id;
        state.googleEventData = {
          ...state.googleEventData,
          [googleEventId]: originalEvent,
        };
        const newEvents = {
          [originalEvent.id]: convertGoogleCalendarEventToEvent(originalEvent),
        };
        state.googleEvents = {
          ...state.googleEvents,
          ...newEvents,
        };
      })
      .addCase(loadCachedOutlookEvents.fulfilled, (state, action) => {
        const { outlookEvents } = action.payload;
        state.outlookEvents = outlookEvents;
      })
      .addCase(fetchOutlookCalendarEvents.fulfilled, (state, action) => {
        state.outlookEvents = action.payload;
      })
      .addCase(updateOutlookCalendarEvent.fulfilled, (state, action) => {
        const updatedEvent = action.payload;
        state.outlookEvents[updatedEvent.id] = updatedEvent;

      })
      .addCase(deleteOutlookCalendarEvent.fulfilled, (state, action) => {
        const eventId = action.payload;
        delete state.outlookEvents[eventId];
        // IndexedDB deletion is handled in the thunk
      });
  },
});

export const {
  setContextMenuActiveForEvent,
  clearStagedTaskEvents,
  stageGoogleEvent,
  revertStagedGoogleEvent,
  clearStaging,
  setTaskEvents,
  updateTaskEvent,
  updateTaskEventFromTaskChangeData,
  setCalendarEventEditModalActiveFor,
} = calendarSlice.actions;

export default calendarSlice.reducer;
