import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaChevronLeft, FaChevronRight, FaSlack } from "react-icons/fa";
import axios from "axios";
import { analytics, slackServerUrl } from "../../utils";
import { BiError, BiSolidError } from "react-icons/bi";
import { toast } from "sonner";
import { FaCircleCheck } from "react-icons/fa6";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import ManageNotionIntegration from "./Notion/ManageNotionIntegration";
import NotionIntegration from "./Notion/NotionIntegration";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { platform } from "@todesktop/client-core";

function Integrations() {
  const userId = useSelector((state) => state.app.uid);

  const { slack: slackConnections = {}, notion: notionWorkSpace = {} } =
    useSelector((state) => state.app.currentUser || {});

  const notionWorkspaceVisible = useSelector(
    (state) => state.app.notionWorkspaceVisible
  );

  const [connectedIntegrations, setConnectedIntegrations] = useState({});
  const [slackIntegrations, setSlackIntegrations] = useState([]);
  const [page, setPage] = useState(0);

  const [selectedIntegration, setSelectedIntegration] = useState("");

  useEffect(() => {
    // if notionWorkspaceVisible, set selectedIntegration to Notion
    if (notionWorkspaceVisible) {
      setSelectedIntegration("Notion");
      setPage(1);
    }
  }, [notionWorkspaceVisible]);

  useEffect(() => {
    console.log("slackConnections", slackConnections);
    if (Object.keys(slackConnections).length > 0) {
      axios
        .get(`${slackServerUrl}/getConnectedWorkspaces`, {
          params: {
            userId: userId,
          },
        })
        .then((response) => {
          // if there are slackIntegrations in the response, then set connectedIntegration to true
          if (response.data.slackIntegrations.length > 0) {
            setSlackIntegrations(response.data.slackIntegrations);
            setConnectedIntegrations((prev) => ({ ...prev, slack: true }));
          } else {
            setSlackIntegrations([]);
            setConnectedIntegrations((prev) => ({ ...prev, slack: false }));
          }
        })
        .catch((error) => {
          console.error(error);
          setConnectedIntegrations((prev) => ({ ...prev, slack: false }));
        });
    }
  }, [slackConnections, userId]);

  useEffect(() => {
    if (notionWorkSpace && Object.keys(notionWorkSpace).length > 0) {
      setConnectedIntegrations((prev) => ({
        ...prev,
        notion: Object.keys(notionWorkSpace).length > 0,
      }));
    }
  }, [notionWorkSpace]);

  const handleRevokeClick = (slack_auth_id) => {
    // Hit the slackServerUrl to revoke the token /revokeTokenForSlackAuthId

    try {
      const axiosPromise = axios
        .post(`${slackServerUrl}/revokeTokenForSlackAuthId`, {
          slackAuthId: slack_auth_id,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log("error", error);
          throw error; // Throw the error instead of returning it
        });

      toast.promise(axiosPromise, {
        loading: "Revoking slack access...",
        success: "Slack access revoked",
        error: "Something went wrong. Please try again.",
      });

      return axiosPromise;
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong. Please try again.");
      return error;
    }
  };

  return (
    <>
      {page === 0 && (
        <div className="flex flex-wrap gap-4">
          <IntegrationBox
            title="Slack"
            imgSrc={require("../../images/Slack-mark-RGB.png")}
            description="Connect your Slack account to create tasks from messages"
            isConnected={connectedIntegrations?.slack}
            setPage={setPage}
            onClick={() => {
              window.open(
                `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
              );

              analytics("Slack connect clicked", {
                source: "Web",
              });
            }}
            setSelectedIntegration={setSelectedIntegration}
          />
          <IntegrationBox
            title="Notion"
            imgSrc={require("../../images/Notion_app_logo.png")}
            description="Add tasks from Notion into Ellie and keep them in sync"
            isConnected={connectedIntegrations?.notion}
            setPage={setPage}
            onClick={() => {
              if (isDesktopApp()) {
                platform.os.openURL(
                  `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
                );
              } else {
                window.open(
                  `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
                );
              }
            }}
            setSelectedIntegration={setSelectedIntegration}
            beta={true}
          />
          <IntegrationBox
            title="Todoist"
            imgSrc={require("../../images/todoist-icon.png")}
            description="Connect your Todoist account to create tasks from pages"
            disabled={true}
            setPage={setPage}
            setSelectedIntegration={setSelectedIntegration}
          />
        </div>
      )}
      {page === 1 ? (
        selectedIntegration === "Slack" ? (
          <ConnectionsPage
            title="Slack"
            description="Connect your Slack account to create tasks from messages"
            connections={slackIntegrations}
            handleRevokeClick={handleRevokeClick}
            imageScr={require("../../images/Slack-mark-RGB.png")}
            setPage={setPage}
            newConnectionClick={() => {
              window.open(
                `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
              );
            }}
          />
        ) : (
          selectedIntegration === "Notion" && (
            <NotionIntegration
              backButtonClicked={() => {
                setPage(0);
              }}
            />
          )
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default Integrations;

const IntegrationBox = ({
  title = null,
  imgSrc = null,
  description = null,
  disabled = false,
  isConnected = false,
  setPage,
  onClick,
  setSelectedIntegration,
  beta = false,
}) => {
  const buttonClass = isConnected
    ? "font-medium text-green-700/70 dark:bg-green-400/10 dark:text-green-500/70 rounded-md px-2 py-1 cursor-default"
    : !disabled
    ? "cursor-pointer font-semibold flex flex-row gap-1 items-center hover:text-purple-700 dark:hover:text-purple-300"
    : "text-neutral-400  dark:text-neutral-400 cursor-default font-semibold";

  const buttonText = !disabled
    ? isConnected
      ? "Connected"
      : "Connect"
    : "Coming Soon";

  return (
    <div className="border border-neutral-200 dark:border-neutral-700 shadow-sm rounded-xl w-64 flex flex-col justify-center items-center overflow-hidden relative">
      {isConnected && !disabled && (
        <FaCircleCheck className="text-green-600 h-5 w-5 absolute top-3 right-3" />
      )}
      <div className="px-4 pt-6 pb-4 flex flex-col gap-2 justify-center items-center">
        <img
          src={imgSrc || "default_image_path"}
          alt={`${title} Logo`}
          className="w-6 h-6 object-contain"
        />
        <div className="font-medium text-lg flex flex-row gap-2 items-center">
          <span>{title}</span>
          {beta && (
            <div className="bg-blue-500 py-[3px] text-white px-2 rounded-full text-xs">
              Beta
            </div>
          )}
        </div>
        <div className="text-neutral-500">{description}</div>
      </div>
      <div
        className={`border-t dark:border-t-neutral-800 pt-2 w-full ${
          isConnected && !disabled
            ? "bg-green-500/10"
            : "bg-neutral-100 dark:bg-neutral-800"
        } flex flex-row gap-2 justify-center py-3 space-between h-12 items-center`}
      >
        {disabled && (
          <div className="text-neutral-400 dark:text-neutral-400 font-semibold">
            Coming Soon
          </div>
        )}
        {!disabled && !isConnected && (
          <div className={buttonClass} onClick={onClick}>
            Connect
            <FaChevronRight className="h-3 w-3" />
          </div>
        )}
        {isConnected && !disabled && (
          <div
            onClick={() => {
              setSelectedIntegration(title);
              setPage(1);
            }}
            className="text-green-700 dark:text-green-500 flex gap-1 items-center px-2 rounded-lg font-medium cursor-pointer hover:text-black hover:dark:text-green-200"
          >
            Manage connections
            <FaChevronRight className="h-3 w-3" />
          </div>
        )}
      </div>
    </div>
  );
};

const ConnectionsPage = ({
  setPage,
  title,
  description,
  connections,
  handleRevokeClick,
  imageScr,
  newConnectionClick,
}) => {
  return (
    <div className="flex flex-col gap-2 justify-start items-start">
      <div
        onClick={() => {
          setPage(0);
        }}
        className="back-button"
      >
        <ArrowLeftCircleIcon className="back-button-icon" />
        Back
      </div>

      <div className="flex justify-start items-center">
        <div className="flex items-center gap-2">
          <img
            src={imageScr || ""}
            alt={`${title} Logo`}
            className="w-5 h-5 object-contain"
          />
          <div className="text-2xl font-medium">{title}</div>
        </div>
      </div>
      <div className="flex flex-col gap-1 justify-start items-start w-full">
        <div className="text-neutral-500">{description}</div>

        <div className="flex flex-col my-2 border border-neutral-200 dark:border-neutral-700 rounded-lg w-full">
          {connections.map((connectionData, index) => {
            return (
              <WorkSpaceRow
                connectionData={connectionData}
                key={connectionData.id}
                isLast={connections.length === index + 1}
                handleRevokeClick={handleRevokeClick}
              />
            );
          })}
        </div>
        <div
          onClick={newConnectionClick}
          className="flex flex-row gap-3 justify-start items-center border  rounded-lg px-4 py-2 cursor-pointer  text-textPrimaryLight dark:text-textPrimaryDark border-neutral-300 dark:border-neutral-700 shadow-sm mt-2 font-medium text-base dark:hover:border-neutral-500 "
        >
          <img
            src={imageScr || ""}
            alt={`${title} Logo`}
            className="w-4 h-4 object-contain"
          />
          Connect another workspace
        </div>
      </div>
    </div>
  );
};

const WorkSpaceRow = ({ connectionData, isLast, handleRevokeClick }) => {
  if (connectionData.error) {
    return (
      <div
        className={`border border-t-0 border-r-0 border-l-0 px-4 py-4 border-neutral-200 dark:border-neutral-700 flex justify-between w-full ${
          isLast && "rounded-lg border-b-0 border-none"
        }`}
      >
        <div className="flex items-center gap-2">
          <div className="bg-yellow-400 dark:bg-neutral-700 h-10 w-10 rounded-md flex flex-row items-center justify-center">
            <BiSolidError className="h-6 w-6 text-white" />
          </div>
          <div className="flex flex-col">
            <div className="text-lg">{connectionData.error?.team_name}</div>
            <div className="text-xs text-neutral-500">
              {connectionData.error?.message}
            </div>
          </div>
        </div>
        <div
          className="text-blue-500 flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-blue-800 cursor-pointer"
          onClick={() => {
            window.open(
              `https://slack.com/oauth/v2/authorize?client_id=3704128363168.6274499426309&scope=commands,team:read&user_scope=users:read,team:read`
            );
          }}
        >
          Re-authenticate
        </div>
      </div>
    );
  }

  return (
    <div
      className={`border border-t-0 border-r-0 border-l-0 px-4 py-4 border-neutral-200 dark:border-neutral-700 flex justify-between ${
        isLast && "rounded-lg border-b-0 border-none"
      }`}
    >
      <div className="flex items-center gap-2">
        <img
          className="bg-neutral-300 dark:bg-neutral-700 h-10 w-10 rounded-md"
          src={connectionData?.team?.icon}
        />
        <div className="flex flex-col">
          <div className="text-lg">{connectionData?.team?.name}</div>
          <div className="text-xs text-neutral-500">
            {connectionData?.team?.url}
          </div>
        </div>
      </div>
      <div
        className=" text-red-600 flex items-center rounded-md px-2 py-1 font-semibold text-xs hover:text-red-700 cursor-pointer"
        onClick={() => {
          handleRevokeClick(connectionData?.slack_auth_id);
        }}
      >
        Revoke access
      </div>
    </div>
  );
};
