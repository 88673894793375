import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { Popover, Tooltip } from "antd";
import {
  AiFillApple,
  AiOutlineApple,
  AiOutlineCalendar,
  AiOutlineEye,
  AiOutlinePlus,
} from "react-icons/ai";
import { Checkbox } from "antd";

import { setUpgradeVisible, updateCurrentUser } from "../../redux/appSlice";
import _ from "lodash";
import {
  googleServerUrl,
  hasCalendarTypeInAccount,
  outlookServerUrl,
} from "../../utils";
import AddCalendar from "./AddCalendar";
import { BsPlus } from "react-icons/bs";
import { BiCheck, BiPlus } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";
import { ColorPicker } from "../Labels/LabelManager";
import { FcGoogle } from "react-icons/fc";
import { auth } from "../../firebase";
import { fetchAllCalendarAccounts } from "../../redux/calendarAccountsSlice";

export default function CalendarSettings() {
  const dispatch = useDispatch();
  const {
    active_calendars = {},
    calendar_accounts = {},
    apple_cal_color = {},
  } = useSelector((state) => state.app.currentUser);
  const userId = useSelector((state) => state.app.uid);
  const subscriptionActive = useSelector(
    (state) => state.app.subscriptionActive
  );

  const {
    googleCalendars = {},
    appleCalendars = {},
    outlookCalendars = {},
    loadedOnce,
  } = useSelector((state) => state.calendarAccounts);

  useEffect(() => {
    if (
      subscriptionActive &&
      userId &&
      Object.keys(calendar_accounts || {}).length > 0
    ) {
      dispatch(fetchAllCalendarAccounts());
    }
  }, [dispatch, subscriptionActive, userId, calendar_accounts]);

  function toggleCalendar(accountId, calendarId) {
    // Make a copy of the active_calendars object
    const newCalendars = _.cloneDeep(active_calendars);
    // If the calendar is already active (.calendars array contains calendarId), deactivate it
    if (newCalendars[accountId]?.calendars?.includes(calendarId)) {
      newCalendars[accountId].calendars = newCalendars[
        accountId
      ].calendars.filter((id) => id !== calendarId);
    }
    // Otherwise, activate it
    else {
      // Check if the account exists
      if (!newCalendars[accountId]) {
        newCalendars[accountId] = {
          calendars: [calendarId],
          id: accountId,
        };
      } else {
        // Check if the account has a .calendars array
        if (!newCalendars[accountId]?.calendars) {
          newCalendars[accountId].calendars = [];
        }

        newCalendars[accountId].calendars.push(calendarId);
      }
    }

    dispatch(
      updateCurrentUser({
        newValues: { active_calendars: newCalendars },
        previousValues: { active_calendars },
      })
    );
  }

  if (Object.keys(calendar_accounts || {}).length === 0) {
    return (
      <AddCalendar>
        <div className="last-sync-icon-container">
          <AiOutlineCalendar className="calendar-settings-icon" />
        </div>
      </AddCalendar>
    );
  }


  if (!loadedOnce && subscriptionActive) {
    return (
      <Tooltip title="Loading calendars...">
        <div className="last-sync-icon-container">
          <AiOutlineCalendar className="h-4 w-4 text-neutral-400 cursor-not-allowed" />
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {!subscriptionActive ? (
        <div
          className="last-sync-icon-container"
          onClick={() => dispatch(setUpgradeVisible(true))}
        >
          <AiOutlineCalendar className="calendar-settings-icon" />
        </div>
      ) : (
        <>
          {(Object.keys(googleCalendars).length > 0 ||
            Object.keys(appleCalendars).length > 0 ||
            Object.keys(outlookCalendars).length > 0) && (
            <Popover
              placement="topRight"
              trigger="click"
              content={
                <div className="calendar-settings-inner">
                  <div className="calendar-settings-title">
                    Active calendars
                  </div>
                  <div className="calendar-settings-content">
                  {Object.keys(appleCalendars).length > 0 &&
                      Object.keys(appleCalendars)
                        .sort()
                        .map((account_id) => {
                        const account = appleCalendars[account_id];

                        if (!account.calendars) return null;
                        return (
                          <div
                            key={account_id + "apple"}
                            className="calendar-settings-item"
                          >
                            <div className="calendar-account flex flex-row gap-1 items-center text-sm font-medium">
                              <AiFillApple className="w-5 h-5" />
                              {account.email}
                            </div>
                            <div className="claendar-list">
                              {account.calendars.map((calendar) => {
                                return (
                                  <AppleCalendarAccountOption
                                    calendar={calendar}
                                    account_id={account_id}
                                    active_calendars={active_calendars}
                                    apple_cal_color={apple_cal_color}
                                    toggleCalendar={toggleCalendar}
                                    key={calendar.id}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                                       {Object.keys(googleCalendars).length > 0 &&
                      Object.keys(googleCalendars)
                        .sort()
                        .map((account_id) => {
                        const account = googleCalendars[account_id];
                        return (
                          <div
                            key={account_id + "google"}
                            className="calendar-settings-item"
                          >
                            <div className="calendar-account flex flex-row gap-1 items-center text-sm font-medium">
                              <FcGoogle className="w-4 h-4" />
                              {account.email}
                            </div>
                            <div className="claendar-list">
                              {account.calendars.map((calendar) => {
                                return (
                                  <GoogleCalendarAccountOption
                                    calendar={calendar}
                                    account_id={account_id}
                                    active_calendars={active_calendars}
                                    toggleCalendar={toggleCalendar}
                                    key={calendar.id}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    {Object.keys(outlookCalendars)?.length > 0 &&
                      Object.keys(outlookCalendars)
                        .sort()
                        .map((account_id) => {
                        const account = outlookCalendars[account_id];
                        return (
                          <div
                            key={account_id + "outlook"}
                            className="calendar-settings-item"
                          >
                            <div className="calendar-account flex flex-row gap-1 items-center text-sm font-medium">
                              <img
                                src={require("../../images/icons8-outlook-calendar-480.png")}
                                alt="outlook icon"
                                className="w-4 h-4"
                              />
                              {account?.email}
                            </div>
                            <div className="claendar-list">
                              {account?.calendars?.map((calendar) => {
                                return (
                                  <OutlookCalendarAccountOption
                                    calendar={calendar}
                                    account_id={account_id}
                                    active_calendars={active_calendars}
                                    toggleCalendar={toggleCalendar}
                                    key={calendar?.id}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <AddCalendar>
                    <div className="add-calendar-text-small">
                      <BiPlus className="icon" />
                      <span>Add calendar account</span>
                    </div>
                  </AddCalendar>
                </div>
              }
            >
              <div className="last-sync-icon-container">
                <AiOutlineCalendar className="calendar-settings-icon" />
              </div>
            </Popover>
          )}

          {Object.keys(googleCalendars).length === 0 &&
            Object.keys(appleCalendars).length === 0 &&
            Object.keys(outlookCalendars).length === 0 && (
              <AddCalendar>
                <div className="last-sync-icon-container">
                  <AiOutlineCalendar className="calendar-settings-icon" />
                </div>
              </AddCalendar>
            )}
        </>
      )}
    </>
  );
}

function AppleCalendarAccountOption({
  calendar,
  account_id,
  toggleCalendar,
  active_calendars,
  apple_cal_color,
}) {
  const [colorPickerActive, setColorPickerActive] = useState(false);
  const [newColor, setNewColor] = useState(
    apple_cal_color[calendar.id] || "#000000"
  );

  useEffect(() => {
    setNewColor(apple_cal_color[calendar.id] || "#000000");
  }, [apple_cal_color]);

  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        toggleCalendar(account_id, calendar.id);
      }}
      className="calendar-account-option w-60 rounded flex flex-row justify-between items-center gap-2 cursor-pointer px-2 py-1 hover:bg-neutral-100 dark:hover:bg-[#3D3D3D]"
    >
      <div className="flex flex-row items-center gap-2">
        {active_calendars[account_id]?.calendars?.includes(calendar.id) && (
          <div
            className="border rounded w-4 h-4 flex justify-center items-center"
            style={{
              borderColor: newColor,
              backgroundColor: newColor,
            }}
          >
            <BiCheck className="w-4 h-4 text-neutral-50" />
          </div>
        )}

        {!active_calendars[account_id]?.calendars?.includes(calendar.id) && (
          <div
            className="border-2 border-neutral-300 rounded w-4 h-4 flex justify-center items-center"
            style={{
              backgroundColor: "transparent",
            }}
          />
        )}

        <span> {calendar.name}</span>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Popover
          open={colorPickerActive}
          onOpenChange={(open) => {
            setColorPickerActive(open);
          }}
          trigger="click"
          placement="bottomRight"
          content={
            <div className="color-picker-container">
              <ColorPicker
                color={newColor}
                onChange={(color) => {
                  setNewColor(color);

                  // Let's also update the apple_cal_color object in the database
                  dispatch(
                    updateCurrentUser({
                      newValues: {
                        apple_cal_color: {
                          ...apple_cal_color,
                          [calendar.id]: color,
                        },
                      },
                      previousValues: { apple_cal_color },
                    })
                  );
                }}
                customDisabled={true}
              />
            </div>
          }
        >
          <FiMoreVertical
            onClick={(e) => {
              e.stopPropagation();
              setColorPickerActive(!colorPickerActive);
            }}
            className="calendar-account-dropdown"
          />
        </Popover>
      </div>
    </div>
  );
}

function GoogleCalendarAccountOption({
  calendar,
  account_id,
  toggleCalendar,
  active_calendars,
}) {
  return (
    <div
      onClick={() => {
        toggleCalendar(account_id, calendar.id);
      }}
      className="calendar-account-option w-60 rounded flex flex-row justify-between items-center gap-2 cursor-pointer px-2 py-1 hover:bg-neutral-100 dark:hover:bg-[#3D3D3D]"
    >
      <div className="flex flex-row items-center gap-2">
        {active_calendars[account_id]?.calendars?.includes(calendar.id) && (
          <div
            className="border rounded w-4 h-4 flex justify-center items-center"
            style={{
              borderColor: calendar.backgroundColor,
              backgroundColor: calendar.backgroundColor,
            }}
          >
            <BiCheck className="w-4 h-4 text-neutral-50" />
          </div>
        )}

        {!active_calendars[account_id]?.calendars?.includes(calendar.id) && (
          <div
            className="border-2 border-neutral-300 rounded w-4 h-4 flex justify-center items-center"
            style={{
              backgroundColor: "transparent",
            }}
          />
        )}

        <span> {calendar.summary}</span>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></div>
    </div>
  );
}

function OutlookCalendarAccountOption({
  calendar,
  account_id,
  toggleCalendar,
  active_calendars,
}) {
  return (
    <div
      onClick={() => {
        toggleCalendar(account_id, calendar.id);
      }}
      className="calendar-account-option w-60 rounded flex flex-row justify-between items-center gap-2 cursor-pointer px-2 py-1 hover:bg-neutral-100 dark:hover:bg-[#3D3D3D]"
    >
      <div className="flex flex-row items-center gap-2">
        {active_calendars[account_id]?.calendars?.includes(calendar?.id) && (
          <div
            className="border rounded w-4 h-4 flex justify-center items-center"
            style={{
              borderColor: calendar.hexColor,
              backgroundColor: calendar.hexColor,
            }}
          >
            <BiCheck className="w-4 h-4 text-neutral-50" />
          </div>
        )}

        {!active_calendars[account_id]?.calendars?.includes(calendar?.id) && (
          <div
            className="border-2 border-neutral-300 rounded w-4 h-4 flex justify-center items-center"
            style={{
              backgroundColor: "transparent",
            }}
          />
        )}

        <span> {calendar?.name}</span>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      ></div>
    </div>
  );
}
