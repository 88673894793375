// App.js or parent component
import { useState } from "react";
import NotionWorkspaceSelection from "./NotionWorkspaceSelection";
import ManageNotionIntegration from "./ManageNotionIntegration";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

export default function NotionIntegration({ backButtonClicked }) {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
  };

  const handleBackToWorkspaces = () => {
    setSelectedWorkspace(null);
  };

  return (
    <div>
      {selectedWorkspace ? (
        <ManageNotionIntegration
          backButtonClicked={handleBackToWorkspaces}
          selectedWorkspace={selectedWorkspace}
        />
      ) : (
        <>
          <div
            onClick={() => {
              backButtonClicked();
            }}
            className="back-button"
          >
            <ArrowLeftCircleIcon className="back-button-icon" />
            Back
          </div>
          <NotionWorkspaceSelection onSelectWorkspace={handleWorkspaceSelect} />
        </>
      )}
    </div>
  );
}
