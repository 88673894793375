import { deleteDB, openDB } from "idb";
import { openCalendarDB } from "./indexDbSchema";

export const getGoogleCalendarEventsFromDB = async (
  timeMin,
  timeMax,
  activeCalendars,
  existingTx = null,
  existingStore = null
) => {
  try {
    let db, tx, store;

    if (existingTx && existingStore) {
      tx = existingTx;
      store = existingStore;
    } else {
      db = await openCalendarDB();
      tx = db.transaction("googleCalendarEvents", "readonly");
      store = tx.objectStore("googleCalendarEvents");
    }

    let events = [];

    const dateTimeIndex = store.index("startTime");
    const dateIndex = store.index("startDate");

    const dateTimeEvents = await dateTimeIndex.getAll(
      IDBKeyRange.bound(timeMin, timeMax)
    );
    const dateEvents = await dateIndex.getAll(
      IDBKeyRange.bound(timeMin.split("T")[0], timeMax.split("T")[0])
    );

    events = dateTimeEvents.concat(dateEvents);

    // Filter events based on active calendars
    return events.filter((event) => {
      for (const calendarGroup of Object.values(activeCalendars)) {
        if (
          calendarGroup.calendars.includes(event.calendarId) &&
          calendarGroup.id === event.accountId
        ) {
          return true;
        }
      }
      return false;
    });
  } catch (error) {
    console.error(
      "Error retrieving Google Calendar events from IndexedDB:",
      error
    );
    return [];
  }
};

export async function updateEventInIndexedDB(
  eventId,
  calendarId,
  accountId,
  newEventData
) {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("googleCalendarEvents", "readwrite");
    const store = tx.objectStore("googleCalendarEvents");

    // Create the composite key
    const compositeKey = `/event/${accountId}/${calendarId}/${eventId}`;

    // Get the existing event
    const existingEvent = await store.get(compositeKey);

    if (existingEvent) {
      // Merge the existing event with the new data
      const updatedEvent = {
        ...existingEvent,
        ...newEventData,
        last_accessed: new Date().toISOString(),
      };

      // Put the updated event back into the store
      await store.put(updatedEvent, compositeKey);
      await tx.done;
    } else {
      console.log(`Event ${compositeKey} not found in IndexedDB`);
    }
  } catch (error) {
    console.error(`Error updating event in IndexedDB:`, error);
  }
}

export async function deleteEventFromIndexedDB(eventId, calendarId, accountId) {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("googleCalendarEvents", "readwrite");
    const store = tx.objectStore("googleCalendarEvents");

    // Create the composite key
    const compositeKey = `/event/${accountId}/${calendarId}/${eventId}`;

    // Delete the event
    await store.delete(compositeKey);
    await tx.done;

    console.log(`Successfully deleted event ${compositeKey} from IndexedDB`);
  } catch (error) {
    console.error(`Error deleting event from IndexedDB:`, error);
  }
}

export const deleteGoogleCalendarIndexedDB = async () => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("googleCalendarEvents", "readwrite");
    const store = tx.objectStore("googleCalendarEvents");

    await store.clear();
    await tx.done;

    console.log(`Successfully cleared Google Calendar events from IndexedDB`);
    return true;
  } catch (error) {
    console.error(
      "Error clearing Google Calendar events from IndexedDB:",
      error
    );
    return false;
  }
};

/* Outlook indexdb */

export const getOutlookCalendarEventsFromDB = async (
  timeMin,
  timeMax,
  activeCalendars,
  existingTx = null,
  existingStore = null
) => {
  try {
    let db, tx, store;

    if (existingTx && existingStore) {
      tx = existingTx;
      store = existingStore;
    } else {
      db = await openCalendarDB();
      tx = db.transaction("outlookCalendarEvents", "readonly");
      store = tx.objectStore("outlookCalendarEvents");
    }

    let events = [];

    const startIndex = store.index("start");

    events = await startIndex.getAll(IDBKeyRange.bound(timeMin, timeMax));

    // Filter events based on active calendars
    return events.filter((event) => {
      for (const calendarGroup of Object.values(activeCalendars)) {
        if (
          calendarGroup.calendars.includes(event.calendarId) &&
          calendarGroup.id === event.accountId
        ) {
          return true;
        }
      }
      return false;
    });
  } catch (error) {
    console.error(
      "Error retrieving Outlook Calendar events from IndexedDB:",
      error
    );
    return [];
  }
};

export const updateOutlookEventInIndexedDB = async (
  eventId,
  calendarId,
  accountId,
  newEventData
) => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("outlookCalendarEvents", "readwrite");
    const store = tx.objectStore("outlookCalendarEvents");

    const compositeKey = `/event/${accountId}/${calendarId}/${eventId}`;

    const existingEvent = await store.get(compositeKey);

    if (existingEvent) {
      const updatedEvent = {
        ...existingEvent,
        ...newEventData,
        last_accessed: new Date().toISOString(),
      };
      await store.put(updatedEvent, compositeKey);
      await tx.done;
      console.log(
        `Successfully updated Outlook event ${compositeKey} in IndexedDB`
      );
    } else {
      console.log(`Outlook event ${compositeKey} not found in IndexedDB`);
    }
  } catch (error) {
    console.error(`Error updating Outlook event in IndexedDB:`, error);
  }
};

export const deleteOutlookEventFromIndexedDB = async (
  eventId,
  calendarId,
  accountId
) => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("outlookCalendarEvents", "readwrite");
    const store = tx.objectStore("outlookCalendarEvents");

    const compositeKey = `/event/${accountId}/${calendarId}/${eventId}`;

    await store.delete(compositeKey);
    await tx.done;

    console.log(
      `Successfully deleted Outlook event ${compositeKey} from IndexedDB`
    );
  } catch (error) {
    console.error(`Error deleting Outlook event from IndexedDB:`, error);
  }
};

export const deleteOutlookCalendarIndexedDB = async () => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("outlookCalendarEvents", "readwrite");
    const store = tx.objectStore("outlookCalendarEvents");

    await store.clear();
    await tx.done;

    console.log(`Successfully cleared Outlook Calendar events from IndexedDB`);
    return true;
  } catch (error) {
    console.error(
      "Error clearing Outlook Calendar events from IndexedDB:",
      error
    );
    return false;
  }
};

/* Apple stuff */

export const getAppleCalendarEventsFromDB = async (
  timeMin,
  timeMax,
  activeCalendars,
  existingTx = null,
  existingStore = null
) => {
  try {
    let db, tx, store;

    if (existingTx && existingStore) {
      tx = existingTx;
      store = existingStore;
    } else {
      db = await openCalendarDB();
      tx = db.transaction("appleCalendarEvents", "readonly");
      store = tx.objectStore("appleCalendarEvents");
    }

    let events = [];

    const startIndex = store.index("start");

    events = await startIndex.getAll(IDBKeyRange.bound(timeMin, timeMax));


    // Filter events based on active calendars
    return events.filter((event) => {
      for (const calendarGroup of Object.values(activeCalendars)) {
        if (
          calendarGroup.calendars.includes(event.calendar) &&
          calendarGroup.id === event.calendarAccount
        ) {
          return true;
        }
      }
      return false;
    });
  } catch (error) {
    console.error(
      "Error retrieving Apple Calendar events from IndexedDB:",
      error
    );
    return [];
  }
};

export const updateAppleEventInIndexedDB = async (
  eventId,
  calendar,
  calendarAccount,
  newEventData
) => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("appleCalendarEvents", "readwrite");
    const store = tx.objectStore("appleCalendarEvents");

    const compositeKey = `/event/${calendarAccount}/${calendar}/${eventId}`;

    const existingEvent = await store.get(compositeKey);

    if (existingEvent) {
      const updatedEvent = {
        ...existingEvent,
        ...newEventData,
        last_accessed: new Date().toISOString(),
      };
      await store.put(updatedEvent, compositeKey);
      await tx.done;
      console.log(
        `Successfully updated Apple event ${compositeKey} in IndexedDB`
      );
    } else {
      console.log(`Apple event ${compositeKey} not found in IndexedDB`);
    }
  } catch (error) {
    console.error(`Error updating Apple event in IndexedDB:`, error);
  }
};

export const deleteAppleEventFromIndexedDB = async (
  eventId,
  calendar,
  calendarAccount
) => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("appleCalendarEvents", "readwrite");
    const store = tx.objectStore("appleCalendarEvents");

    const compositeKey = `/event/${calendarAccount}/${calendar}/${eventId}`;

    await store.delete(compositeKey);
    await tx.done;

    console.log(
      `Successfully deleted Apple event ${compositeKey} from IndexedDB`
    );
  } catch (error) {
    console.error(`Error deleting Apple event from IndexedDB:`, error);
  }
};

export const deleteAppleCalendarIndexedDB = async () => {
  try {
    const db = await openCalendarDB();
    const tx = db.transaction("appleCalendarEvents", "readwrite");
    const store = tx.objectStore("appleCalendarEvents");

    await store.clear();
    await tx.done;

    console.log(`Successfully cleared Apple Calendar events from IndexedDB`);
    return true;
  } catch (error) {
    console.error(
      "Error clearing Apple Calendar events from IndexedDB:",
      error
    );
    return false;
  }
};
