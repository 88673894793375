import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentUser, updatePowerFtux } from "../../redux/appSlice";
import { TbTargetArrow, TbExternalLink, TbApi, TbFlag2 } from "react-icons/tb";
import { MdMailOutline } from "react-icons/md";
import { BiBarChartSquare } from "react-icons/bi";
import { Modal, Select, Switch } from "antd";
import { analytics } from "../../utils";
import { AiOutlineApi } from "react-icons/ai";
import { RiAttachment2 } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { HiSun } from "react-icons/hi";
import { BsCloudSun } from "react-icons/bs";
import moment from "moment";

export default function PowerFeatures({ setToolTipActiveForTab }) {
  const power_feature_settings = useSelector(
    (state) =>
      state.app.currentUser.power_feature_settings || {
        analytics_enabled: true,
        due_dates: {
          enabled: false,
          number_of_days_to_show_indicator: 7,
        },
        api_access_enabled: false,
        priority_enabled: false,
        attachments_enabled: false,
        daily_planning_enabled: false,
      }
  );

  // For email_forwarding, we get the setting from email_forwarding_id off of the user itself
  const email_forwarding_id = useSelector(
    (state) => state.app.currentUser.email_forwarding_id || null
  );

  const dispatch = useDispatch();

  const [analyticsModalVisible, setAnalyticsModalVisible] = useState(false);
  const [dailyPlanningModalVisible, setDailyPlanningModalVisible] =
    useState(false);

  const timeRange = Array.from(
    { length: 25 },
    (_, i) => i.toString().padStart(2, "0") + ":00"
  );

  const {
    dailyPlanningTimeEnabled = false,
    dailyPlanningTime = "09:00",
    dailyShutDownTimeEnabled = false,
    dailyShutDownTime = "18:00",
    time_format = "12_hour",
  } = useSelector((state) => state.app.currentUser || {});

  return (
    <div>
      <div className="settings">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
          <Modal
            title={null}
            footer={null}
            closeIcon={null}
            open={analyticsModalVisible}
            onCancel={() => setAnalyticsModalVisible(false)}
            onChat={(open) => setAnalyticsModalVisible(open)}
          >
            <div className="p-6 flex flex-col gap-2 items-start relative">
              <div className="flex flex-row justify-between items-center w-full mb-2">
                <div className="text-xl font-medium">
                  Analytics tab now available 🙌
                </div>
                <div
                  onClick={() => {
                    setAnalyticsModalVisible(false);
                  }}
                  className="bg-neutral-200 text-neutral-500 hover:bg-neutral-300 dark:bg-neutral-600 dark:text-white dark:hover:bg-neutral-700 p-2 rounded-full cursor-pointer"
                >
                  <IoMdClose className="h-4 w-4" />
                </div>
              </div>
              <img
                src={require("../../images/analytics_menu.png")}
                alt="Analytics"
                className="w-full h-full object-cover object-center rounded-md"
              />

              <div className="text-base">
                You can access it by clicking the button at the top right (with
                your initials or avatar) and then "Analytics"
              </div>
            </div>
          </Modal>
          <Modal
            title={null}
            footer={null}
            closeIcon={null}
            open={dailyPlanningModalVisible}
            onCancel={() => setDailyPlanningModalVisible(false)}
            onChat={(open) => setDailyPlanningModalVisible(open)}
          >
            <div className="p-6 flex flex-col gap-2 items-start relative">
              <div className="flex flex-row justify-between items-center w-full mb-2">
                <div className="text-xl font-medium">
                  Daily planning tab now available 🙌
                </div>
                <div
                  onClick={() => {
                    setDailyPlanningModalVisible(false);
                  }}
                  className="bg-neutral-200 text-neutral-500 hover:bg-neutral-300 dark:bg-neutral-600 dark:text-white dark:hover:bg-neutral-700 p-2 rounded-full cursor-pointer"
                >
                  <IoMdClose className="h-4 w-4" />
                </div>
              </div>
              <img
                src={require("../../images/daily_planning_menu.png")}
                alt="Daily Planning"
                className="w-full h-full object-cover object-center rounded-md"
              />

              <div className="text-base">
                You can access it by clicking the button at the top right (with
                your initials or avatar) and then "Daily planning"
              </div>

              <div className="flex flex-col gap-2 items-start bg-neutral-100 border border-neutral-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700 py-4 px-4 w-full">
                <div className="text-lg font-medium">🔔 Need a reminder?</div>

                <div className="text-sm">
                  On desktop (and soon iOS), we'll send you a reminder
                  notification each day.
                </div>

                <div className="settings-item">
                  <div className="settings-item-title">
                    Daily Planning notifications
                  </div>

                  <div className="settings-item-value w-full flex-col gap-4">
                    <div className="flex flex-col gap-6 w-full">
                      <div className="flex flex-row items-center gap-2">
                        <span>Daily Planning notifications</span>
                        <Switch
                          size="small"
                          checked={dailyPlanningTimeEnabled}
                          onChange={(value) => {
                            dispatch(
                              updateCurrentUser({
                                newValues: {
                                  dailyPlanningTimeEnabled: value,
                                },
                                previousValues: {
                                  dailyPlanningTimeEnabled:
                                    dailyPlanningTimeEnabled,
                                },
                              })
                            );
                          }}
                        />
                      </div>
                      {dailyPlanningTimeEnabled && (
                        <div className="settings-item-value w-full">
                          <div className="flex flex-col items-start gap-4 w-full">
                            <div className="flex flex-row gap-2 items-center w-full">
                              <span>Remind me</span>
                              <Select
                                className="min-w-fit"
                                defaultValue={"09:00"}
                                value={
                                  time_format === "12_hour"
                                    ? moment(dailyPlanningTime, "HH:mm").format(
                                        "h:mm A"
                                      )
                                    : dailyPlanningTime
                                }
                                onChange={(value) => {
                                  dispatch(
                                    updateCurrentUser({
                                      newValues: {
                                        dailyPlanningTime: value,
                                      },
                                      previousValues: {
                                        dailyPlanningTime: dailyPlanningTime,
                                      },
                                    })
                                  );
                                }}
                                options={
                                  timeRange.map((time) => {
                                    return {
                                      value: time,
                                      label:
                                        time_format === "12_hour"
                                          ? moment(time, "HH:mm").format(
                                              "h:mm A"
                                            )
                                          : time,
                                    };
                                  }) || []
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-6 w-full">
                    <div className="flex flex-row items-center gap-2">
                      <span>Daily Shutdown notifications</span>
                      <Switch
                        size="small"
                        checked={dailyShutDownTimeEnabled}
                        onChange={(value) => {
                          dispatch(
                            updateCurrentUser({
                              newValues: {
                                dailyShutDownTimeEnabled: value,
                              },
                              previousValues: {
                                dailyShutDownTimeEnabled:
                                  dailyShutDownTimeEnabled,
                              },
                            })
                          );
                        }}
                      />
                    </div>
                    {dailyShutDownTimeEnabled && (
                      <div className="settings-item-value w-full">
                        <div className="flex flex-col items-start gap-4 w-full">
                          <div className="flex flex-row gap-2 items-center w-full">
                            <span>Remind me</span>
                            <Select
                              className="min-w-fit"
                              defaultValue={
                                time_format === "12_hour" ? "09:00 PM" : "21:00"
                              }
                              value={
                                time_format === "12_hour"
                                  ? moment(dailyShutDownTime, "HH:mm").format(
                                      "h:mm A"
                                    )
                                  : dailyShutDownTime
                              }
                              onChange={(value) => {
                                dispatch(
                                  updateCurrentUser({
                                    newValues: {
                                      dailyShutDownTime: value,
                                    },
                                    previousValues: {
                                      dailyShutDownTime: dailyShutDownTime,
                                    },
                                  })
                                );
                              }}
                              options={
                                timeRange.map((time) => {
                                  return {
                                    value: time,
                                    label:
                                      time_format === "12_hour"
                                        ? moment(time, "HH:mm").format("h:mm A")
                                        : time,
                                  };
                                }) || []
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <FeatureCard
            icon={<TbTargetArrow />}
            title="Due dates"
            description="Add due dates to your tasks (and even reminder notifications)."
            image={require("../../images/due_dates4.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/due-dates"
            isActive={power_feature_settings.due_dates?.enabled || false}
            settingsToggled={() => {
              setToolTipActiveForTab("due_dates");
              dispatch(
                updatePowerFtux({
                  due_date_topbar: true,
                })
              );
            }}
            setIsActive={(checked) => {
              if (checked) {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      power_feature_settings: {
                        due_dates: {
                          enabled: checked,
                          number_of_days_to_show_indicator: 7,
                        },
                      },
                    },
                    previousValues: {
                      power_feature_settings: power_feature_settings,
                    },
                  })
                );
              } else {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      power_feature_settings: {
                        due_dates: {
                          enabled: checked,
                          notifications_enabled: false,
                        },
                      },
                    },
                    previousValues: {
                      power_feature_settings: power_feature_settings,
                    },
                  })
                );
              }
            }}
          />

          <FeatureCard
            icon={<TbFlag2 />}
            title="Task Priority"
            description="Add priority flags directly on your task to easily see what's important."
            image={require("../../images/priority_2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/task-priority"
            isActive={power_feature_settings.priority_enabled}
            settingsToggled={() => {
              // setToolTipActiveForTab("api_access");
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      priority_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<MdMailOutline />}
            title="Email forwarding"
            description="Forward emails directly into Ellie to create tasks."
            image={require("../../images/email2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/email-greater-than-task"
            isActive={
              email_forwarding_id != null && email_forwarding_id.length > 0
            }
            settingsToggled={() => {
              setToolTipActiveForTab("emailForwarding");
            }}
            setIsActive={(checked) => {
              // Update redux

              if (checked) {
                const id = Math.random().toString(36).substring(2, 15);

                dispatch(
                  updateCurrentUser({
                    newValues: {
                      email_forwarding_id: id,
                    },
                    previousValues: {
                      email_forwarding_id: email_forwarding_id,
                    },
                  })
                );
              } else {
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      email_forwarding_id: null,
                    },
                    previousValues: {
                      email_forwarding_id: email_forwarding_id,
                    },
                  })
                );
              }
            }}
          />

          <FeatureCard
            icon={<BsCloudSun />}
            title="Daily Planning"
            description="Guided walkthroughs to help you plan & reflect on your day."
            image={require("../../images/daily_planning.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/daily-planning-ritual"
            isActive={power_feature_settings.daily_planning_enabled}
            settingsToggled={() => {
              setDailyPlanningModalVisible(true);
            }}
            setIsActive={(checked) => {
              // Update redux
              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      daily_planning_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<RiAttachment2 />}
            title="Attachments"
            description="Attach files (PDF, images, etc...) to your tasks."
            image={require("../../images/attachments2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/tasks/task-attachments"
            isActive={power_feature_settings.attachments_enabled}
            setIsActive={(checked) => {
              // Update redux
              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      attachments_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<BiBarChartSquare />}
            title="Analytics"
            description="Visualize where you spend your time and how you're progressing."
            image={require("../../images/analytics_2.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/analytics"
            isActive={power_feature_settings.analytics_enabled}
            settingsToggled={() => {
              setAnalyticsModalVisible(true);
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      analytics_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />

          <FeatureCard
            icon={<AiOutlineApi />}
            title="API Token"
            description="Enable this to allow third party apps (like Zapier) to connect to Ellie."
            image={require("../../images/integrations3.png")}
            learnMoreLink="https://guide.ellieplanner.com/features/zapier-integration"
            isActive={power_feature_settings.api_access_enabled}
            settingsToggled={() => {
              setToolTipActiveForTab("api_access");
            }}
            setIsActive={(checked) => {
              // Update redux

              dispatch(
                updateCurrentUser({
                  newValues: {
                    power_feature_settings: {
                      ...power_feature_settings,
                      api_access_enabled: checked,
                    },
                  },
                  previousValues: {
                    power_feature_settings: power_feature_settings,
                  },
                })
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

// Feature card
export function FeatureCard({
  icon,
  title,
  description,
  image,
  learnMoreLink,
  isActive = false,
  setIsActive,
  settingsToggled,
}) {
  return (
    <div className="flex flex-col gap-4 items-center border rounded-lg border-neutral-200 dark:border-neutral-700 overflow-hidden relative dark:text-neutral-200">
      <div className="flex items-center justify-center overflow-hidden w-full h-32">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="flex-1 flex flex-col items-start justify-between gap-3 pb-5 px-5">
        <div className="flex flex-col items-start gap-2">
          <div className="flex items-center gap-2">
            <div>{icon}</div>
            <div>
              <div className="text-sm font-semibold">{title}</div>
            </div>
          </div>
          <div className="text-xs dark:text-neutral-400">{description}</div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Switch
            size="small"
            checked={isActive}
            onChange={(checked) => {
              setIsActive(checked);

              analytics("Power feature toggled", {
                source: "web",
                feature: title,
                value: checked,
              });

              if (checked && settingsToggled) {
                settingsToggled();
              }
            }}
          />
          <button
            onClick={() => {
              window.open(learnMoreLink, "_blank");
            }}
            className="flex flex-row items-center gap-1 text-xs"
          >
            <span>Learn more</span> <TbExternalLink />
          </button>
        </div>
      </div>
    </div>
  );
}
