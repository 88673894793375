import "./Settings.css";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { LogoutIcon } from "@heroicons/react/24/outline";

import { setToastVisible, updateCurrentUser } from "../../redux/appSlice";
import { Default, Mobile } from "../../mediaUtils";
import SettingsAvatar from "./SettingsAvatar";

import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

import { getAuth, signOut } from "firebase/auth";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { getVersion } from "@todesktop/client-core/app";
import { app_version, isDev } from "../../utils";
import { deleteCalendarDB } from "../../redux/calendars/indexDbSchema";
import { toast } from "sonner";

export default function Account() {
  const { currentUser } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const [nameTemp, setNameTemp] = useState(currentUser.name);

  const [changePasswordActive, setChangePasswordActive] = useState(false);
  const [changeEmailActive, setChangeEmailActive] = useState(false);

  useEffect(() => {
    setNameTemp(currentUser.name);
  }, [currentUser]);

  const auth = getAuth();
  const user = auth.currentUser;

  const userId = useSelector((state) => state.app.uid);

  return (
    <div>
      {changeEmailActive && (
        <ChangeEmail setChangeEmailActive={setChangeEmailActive} />
      )}
      {changePasswordActive && (
        <ChangePassword setChangePasswordActive={setChangePasswordActive} />
      )}

      {!changeEmailActive && !changePasswordActive && (
        <div className="settings">
          <SettingsAvatar
            url={currentUser?.avatar_url}
            onUpload={(url) => {
              dispatch(
                updateCurrentUser({
                  newValues: { avatar_url: url },
                  previousValues: { avatar_url: currentUser?.avatar_url },
                })
              );
            }}
            name={currentUser.name}
          />

          <div className="settings-rowdivider" />
          <div className="settings-item">
            <div className="settings-item-title">Name</div>
            <div className="dl" style={{ rowGap: "11px" }}>
              <input
                className="settings-item-input"
                value={nameTemp}
                onChange={(e) => {
                  setNameTemp(e.target.value);
                }}
                placeholder="Name"
                onBlur={() => {
                  // Update user with name
                  if (currentUser.name !== nameTemp) {
                    dispatch(
                      updateCurrentUser({
                        newValues: { name: nameTemp },
                        previousValues: { name: currentUser.name },
                      })
                    );
                  }
                }}
              />
            </div>
          </div>

          <div className="settings-rowdivider" />
          <div className="settings-item">
            <div className="settings-item-title">Email</div>
            <div className="dl">
              <div>{user.email}</div>
              <div
                onClick={() => {
                  setChangeEmailActive(true);
                }}
                className="button"
              >
                Change Email
              </div>
            </div>
          </div>

          <div className="settings-rowdivider" />

          <div className="settings-item">
            <div className="settings-item-title">Password</div>
            <div className="sl">
              <div
                onClick={() => {
                  setChangePasswordActive(true);
                }}
                className="button"
              >
                Change Password
              </div>
            </div>
          </div>

          <div className="settings-rowdivider" />

          <div className="settings-item">
            <div className="settings-item-title">App Version</div>
            <div className="settings-item-value">
              {isDesktopApp() ? getVersion() : app_version}
            </div>
          </div>

          <div className="settings-item mb-2">
            <div className="settings-item-title">
             Reset local data (for support)
            </div>
            <div className="sl">
              <div
                onClick={async () => {
                  await deleteCalendarDB();

                  // Refresh the page
                  window.location.reload();
                }}
                className="button"
              >
                Reset local data
              </div>
            </div>
          </div>

          {isDev && (
            <div onClick={() => {}} className="settings-item">
              <div className="settings-item-title">User ID (for support)</div>
              <div
                className="settings-item-value cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(userId);
                  toast.message("User ID copied to clipboard");
                }}
              >
                {userId}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
