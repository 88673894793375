import React, { useEffect, useRef, useState } from "react";
import { pushNotifications, nativeWindow } from "@todesktop/client-core";
import { useDispatch, useSelector } from "react-redux";
import { setCardModalActive } from "../../redux/appSlice";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { addHours, addSeconds, isAfter } from "date-fns";

function TimeboxNotifications() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.app.uid);
  const scheduledNotifications = useRef(new Map());

  const timeboxNotificationBefore = useSelector(
    (state) => state.app.timeboxNotificationBefore || 10 * 60
  );
  const show_declined_events = useSelector(
    (state) => state.app.currentUser?.show_declined_events || true
  );

  const appleEvents = useSelector((state) => state.calendar.appleEvents || {});

  const googleEvents = useSelector(
    (state) => state.calendar.googleEvents || {}
  );

  const outlookEvents = useSelector(
    (state) => state.calendar.outlookEvents || {}
  );

  const taskEvents = useSelector((state) => state.calendar.taskEvents);

  function fetchAndProcessAllEvents(events) {
    scheduledNotifications.current.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
    scheduledNotifications.current.clear();

    // Define the start of today and the end of tomorrow
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const endOfTomorrow = new Date(today);
    endOfTomorrow.setDate(today.getDate() + 2);
    endOfTomorrow.setHours(0, 0, 0, 0);

    // Rebuild the scheduled notifications list
    events.forEach((event) => {
      const eventStart = new Date(event.start);
      if (isAfter(eventStart, today) && eventStart < endOfTomorrow) {
        scheduleNotification(event);
      }
    });
  }

  // Every time allEvents changes, we want to schedule out all notifications from now until the future
  useEffect(() => {
    // Process all events
    try {
      const allEvents = [
        ...Object.values(appleEvents || {}).map((appleEvent) => {
          return {
            ...appleEvent,
            editable:
              appleEvent.extendedProps?.recurring == true ? false : true,
          };
        }),
        ...Object.values(googleEvents || {})
          .filter(
            (googleEvent) =>
              // If extendedProps.ellie_task_id exists, then we don't want to show it'
              !googleEvent.extendedProps ||
              !googleEvent.extendedProps.ellie_task_id
          )
          // Filter declined if show_declined_events is false
          .filter((googleEvent) =>
            !show_declined_events ? !googleEvent.extendedProps?.declined : true
          )
          .map((googleEvent) => {
            // Go through and update textColor and backgroundColor based on the primary color

            return {
              ...googleEvent,
            };
          }),
        ...Object.values(outlookEvents || {})
          .filter(
            (outlookEvent) =>
              !outlookEvent.extendedProps ||
              !outlookEvent.extendedProps.ellie_task_id
          )
          .filter((outlookEvent) => {
            // Filter declined if show_declined_events is false
            return !show_declined_events
              ? !outlookEvent.extendedProps?.declined
              : true;
          })
          .map((outlookEvent) => {
            return {
              ...outlookEvent,
            };
          }),
        ...Object.values(taskEvents),
      ];

      fetchAndProcessAllEvents(allEvents);
    } catch (e) {
      console.error("Error processing all events: ", e);
    }
  }, [
    timeboxNotificationBefore,
    show_declined_events,
    appleEvents,
    googleEvents,
    outlookEvents,
    taskEvents,
  ]);

  useEffect(() => {
    return () => {
      scheduledNotifications.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    };
  }, []);

  // Function to take an event and convert it to "10:00AM - 11:00AM"
  const formatEventTime = (event) => {
    try {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);

      const startString = eventStart.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      const endString = eventEnd.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${startString} - ${endString}`;
    } catch (e) {
      console.error("Error formatting event time: ", e);
      return "Starting soon";
    }
  };

  const scheduleNotification = (event) => {
    const eventId = event.id;
    const eventTitle = event.title || "Calendar event";

    const eventType = event.extendedProps?.type || "task";
    const notificationTime = addSeconds(
      new Date(event.start),
      -timeboxNotificationBefore
    );

    const delay = notificationTime.getTime() - new Date().getTime();

    if (delay > 0) {


      const timeoutId = setTimeout(async () => {
        new Notification(eventTitle, {
          body: `${formatEventTime(event)}`,
          data: {
            eventId: eventId,
          },
        }).onclick = () => {
          // Bring the main window to the front using ToDesktop
          nativeWindow.focus();

          if (eventType === "task") {
            dispatch(setCardModalActive(eventId));
          }
        };

        // Remove from the map after notification is sent
        scheduledNotifications.current.delete(eventId);
      }, delay);

      // Store the timeout ID in the map
      scheduledNotifications.current.set(eventId, timeoutId);
    }
  };

  return <></>;
}

export default TimeboxNotifications;
