import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  convertGoogleCalendarEventToEvent,
  convertGoogleEventsToEvents,
  getTimeMinAndTimeMax,
} from "./calendarUtils";
import axios from "axios";
import { googleServerUrl } from "../../utils";
import { setToastVisible } from "../appSlice";
import {
  getGoogleCalendarEventsFromDB,
  updateEventInIndexedDB,
  deleteEventFromIndexedDB,
} from "./calendarIndexDBUtils";
import { openCalendarDB } from "./indexDbSchema";

export const fetchGoogleCalendarEvents = createAsyncThunk(
  "calendar/fetchGoogleCalendarEvents",
  async (signal, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;
    const userId = getState().app.uid;
    const calendarDate = getState().tasks.calendarDate;

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    try {
      const response = await axios.get(`${googleServerUrl}/getCalendarEvents`, {
        params: {
          userId,
          timeMin,
          timeMax,
          active_calendars: activeCalendarsJson,
        },
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      });

      if (signal?.aborted) throw new Error("Cancelled");

      if (response.data && response.data.events?.length > 0) {
        const apiEvents = response.data.events;

        try {
          const db = await openCalendarDB();
          const tx = db.transaction("googleCalendarEvents", "readwrite");
          const store = tx.objectStore("googleCalendarEvents");

          const existingEvents = await getGoogleCalendarEventsFromDB(
            timeMin,
            timeMax,
            active_calendars,
            tx,
            store
          );

          const apiEventIds = new Set(
            apiEvents.map(
              (e) => `/event/${e.accountId}/${e.calendarId}/${e.id}`
            )
          );

          for (const existingEvent of existingEvents) {
            const key = `/event/${existingEvent.accountId}/${existingEvent.calendarId}/${existingEvent.id}`;

            if (!apiEventIds.has(key)) {
              console.log("DELETING EVENT", existingEvent);

              await store.delete(key);
            }
          }

          const currentTimestamp = new Date().toISOString();

          for (const event of apiEvents) {
            const key = `/event/${event.accountId}/${event.calendarId}/${event.id}`;
            await store.put({ ...event, last_accessed: currentTimestamp }, key);
          }

          await tx.done;
        } catch (error) {
          console.error(
            `Error storing Google Calendar events in IndexedDB:`,
            error
          );

          dispatch(
            setToastVisible({
              toastType: "error",
              message: `Something went wrong storing Google Calendar events in IndexedDB. Please contact support ${error}`,
            })
          );
        }

        const newEvents = convertGoogleEventsToEvents(apiEvents);
        const newGoogleEvents = newEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});

        const newGoogleEventData = apiEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});

        return fulfillWithValue({
          newEvents: newGoogleEvents,
          newGoogleEventData: newGoogleEventData,
        });
      } else {
        return fulfillWithValue({});
      }
    } catch (error) {
      if (axios.isCancel(error) || error.message === "Cancelled") {
        throw new Error("Cancelled");
      }
      console.error("Error fetching or storing Google Calendar events:", error);
      dispatch(
        setToastVisible({
          toastType: "error",
          message: `Something went wrong talking to Google calendar. Please contact support ${error}`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateGoogleCalendarEvent = createAsyncThunk(
  "calendar/updateGoogleCalendarEvent",
  async (
    { eventId, newData, sendUpdates },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;
    const googleEventId = getState().calendar.googleEvents[eventId].id;
    const googleEvent = getState().calendar.googleEventData[googleEventId];

    try {
      const response = await axios.post(
        `${googleServerUrl}/updateCalendarEvent`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            eventId: googleEvent.id,
            calendarId: googleEvent.calendarId,
            sendUpdates: sendUpdates,
          },
        }
      );

      if (response.data?.event?.id) {
        const converted = convertGoogleCalendarEventToEvent({
          ...response.data.event,
        });

        if (converted) {
          // Update the event in IndexedDB
          await updateEventInIndexedDB(
            googleEvent.id,
            googleEvent.calendarId,
            googleEvent.accountId,
            response.data.event
          );

          return fulfillWithValue({
            newEvent: converted,
          });
        }
      }
      throw new Error("Failed to update event");
    } catch (error) {
      console.log(error);
      return rejectWithValue(googleEvent);
    }
  }
);

export const deleteCalendarEvent = createAsyncThunk(
  "calendar/deleteCalendarEvent",
  async (
    { googleEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;
    const googleEventId = googleEvent.id;

    try {
      const response = await axios.post(
        `${googleServerUrl}/deleteCalendarEvent`,
        googleEvent,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            calendarId: googleEvent.calendarId,
            eventId: googleEventId,
          },
        }
      );

      if (response.status === 200) {
        // Delete the event from IndexedDB
        await deleteEventFromIndexedDB(
          googleEvent.id,
          googleEvent.calendarId,
          googleEvent.accountId
        );
        return fulfillWithValue(googleEventId);
      }
      throw new Error("Failed to delete event");
    } catch (error) {
      console.log(error);
      dispatch(
        setToastVisible({
          toastType: "error",
          message:
            "Something went wrong talking to Google calendar. Please contact support",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const loadCachedGoogleEvents = createAsyncThunk(
  "calendar/loadCachedGoogleEvents",
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const {
        active_calendars = {},
        active_calendar_type = "week",
        mode = "kanban",
      } = getState().app.currentUser;

      const calendarDate = getState().tasks.calendarDate;
      const { timeMin, timeMax } = getTimeMinAndTimeMax(
        active_calendar_type,
        calendarDate,
        mode
      );

      const cachedEvents = await getGoogleCalendarEventsFromDB(
        timeMin,
        timeMax,
        active_calendars
      );

      if (cachedEvents.length > 0) {
        const newEvents = convertGoogleEventsToEvents(cachedEvents);
        const newGoogleEvents = newEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        const newGoogleEventData = cachedEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});

        return fulfillWithValue({
          googleEvents: newGoogleEvents,
          googleEventData: newGoogleEventData,
        });
      } else {
        return fulfillWithValue({});
      }
    } catch (error) {
      console.error("Error loading cached Google Calendar events:", error);
      return rejectWithValue(error);
    }
  }
);

// ... other thunks remain the same

export const updateRecurringCalendarEvent = createAsyncThunk(
  "calendar/updateRecurringCalendarEvent",
  async (
    { eventId, newData, sendUpdates, recurringUpdateType = "all" },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const googleEventId = getState().calendar.googleEvents[eventId].id;

    const calendarEvent = getState().calendar.googleEvents[eventId];
    const googleEvent = getState().calendar.googleEventData[googleEventId];

    const recurringEventId = googleEvent.recurringEventId;
    try {
      return axios
        .post(`${googleServerUrl}/updateRecurringCalendarEvent`, newData, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: userId,
            accountId: googleEvent.accountId,
            eventId: googleEvent.id,
            calendarId: googleEvent.calendarId,
            sendUpdates: sendUpdates,
            recurringEventId: recurringEventId,
            type: recurringUpdateType,
          },
        })
        .then((res) => {
          if (res.data?.event?.id) {
            // Update the event in the googleEvents object

            var converted = convertGoogleCalendarEventToEvent({
              ...res.data.event,
            });

            if (converted) {
              return fulfillWithValue({
                newEvent: converted,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err != null) {
            return rejectWithValue(googleEvent);
          }
        });
    } catch (error) {
      return rejectWithValue(googleEvent);
    }
  }
);
