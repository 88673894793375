import moment from "moment";

export function convertGoogleEventsToEvents(googleEvents) {
  // Convert google events to events
  // Remove nulls
  return googleEvents
    .map((googleEvent) => convertGoogleCalendarEventToEvent(googleEvent))
    .filter((event) => event !== null);
}

export function convertGoogleCalendarEventToEvent(googleEvent) {
  var isAllDay = false;

  if (googleEvent.start == null) {
    return null;
  }

  if (googleEvent.start.date) {
    isAllDay = true;
  }

  const event = {
    id: googleEvent.id,
    title:
      googleEvent.summary ||
      (googleEvent.visibility === "private"
        ? "Busy (private)"
        : "Untitled Event"),
    start: isAllDay ? googleEvent.start.date : googleEvent.start.dateTime,
    end: isAllDay ? googleEvent.end.date : googleEvent.end.dateTime,
    color: googleEvent.colorId,
    backgroundColor: googleEvent.backgroundColor,
    //  textColor: "var(--calendar-text-color)",
    //  borderColor: googleEvent.backgroundColor,
    allDay: isAllDay,
    googleEvent: true,
    editable: true,
    extendedProps: {
      type: "google",
      calendarId: googleEvent.calendarId,
      googleEventId: googleEvent.id,
      ellie_task_id: googleEvent.extendedProperties?.private?.ellie_task_id,
      declined:
        googleEvent.attendees?.find((attendee) => attendee.self)
          ?.responseStatus === "declined"
          ? true
          : false,
    },
  };

  return event;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result;
}

export var colourIsLight = function (hex) {
  // Convert hex to RGB first (removing #)
  var rgb = hexToRgb(hex);

  if (rgb) {
    var r = parseInt(rgb[1], 16);
    var g = parseInt(rgb[2], 16);
    var b = parseInt(rgb[3], 16);

    // Counting the perceptive luminance
    // human eye favors green color...
    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  }

  return true;
};

export function convertTasksToEvents(tasks, labels) {
  return tasks
    .map((task) => {
      // If there is no estimated time, make it 1 hour (in seconds)
      const estimated_time = task.estimated_time || 3600;

      var start_date = task.start;

      if (!start_date) {
        return null;
      }

      // Check if start_date is instanceof firebase timestamp, if so, convert to date
      if (start_date.toDate) {
        start_date = start_date.toDate();
      }

      // The end time is the start time plus the estimated time (using moment), then convert to date
      const end_date = moment(start_date)
        .add(estimated_time, "seconds")
        .toDate();

      return {
        title: task.description,
        start: start_date,
        end: end_date,
        allDay: false, // will make the time show
        editable: true,
        backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
        textColor: colourIsLight(labels?.[task.label]?.color || "#FFE3C9")
          ? "black"
          : "white",
        borderColor: labels?.[task.label]?.color || "#FFE3C9",
        id: task.id,
        extendedProps: {
          type: "task",
          calendar_events: task.calendar_events,
        },
      };
    })
    .filter((event) => event !== null);
}

export function convertTaskToEvent(task, labels) {
  // If there is no estimated time, make it 1 hour (in seconds)
  const estimated_time = task.estimated_time || 3600;

  var start_date = task.start;

  if (!start_date) {
    return null;
  }

  // Check if start_date is instanceof firebase timestamp, if so, convert to date
  if (start_date.toDate) {
    start_date = start_date.toDate();
  }

  // The end time is the start time plus the estimated time (using moment), then convert to date
  const end_date = moment(start_date).add(estimated_time, "seconds").toDate();

  return {
    title: task.description,
    start: start_date,
    end: end_date,
    allDay: false, // will make the time show
    editable: true,
    backgroundColor: labels?.[task.label]?.color || "#FFE3C9",
    textColor: colourIsLight(labels?.[task.label]?.color || "#FFE3C9")
      ? "black"
      : "white",
    borderColor: labels?.[task.label]?.color || "#FFE3C9",
    id: task.id,
    extendedProps: {
      type: "task",
    },
  };
}

export function getTimeMinAndTimeMax(
  active_calendar_type,
  calendarDate,
  mode = "kanban"
) {
  var timeMin =
    active_calendar_type === "month" && mode !== "kanban"
      ? moment(calendarDate, "YYYY-MM-DD")
          .startOf("month")
          .add(-2, "weeks")
          .toISOString()
      : moment(calendarDate, "YYYY-MM-DD")
          .subtract(1, "week")
          .startOf("day")
          .toISOString();
  var timeMax =
    active_calendar_type === "month" && mode !== "kanban"
      ? moment(calendarDate, "YYYY-MM-DD")
          .endOf("month")
          .add(2, "weeks")
          .toISOString()
      : moment(calendarDate, "YYYY-MM-DD")
          .add(1, "week")
          .endOf("day")
          .toISOString();

  return { timeMin, timeMax };
}
