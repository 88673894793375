import { openDB } from "idb";

export const DB_NAME = "calendarDB";
export const CALENDAR_ACCOUNTS_DB_NAME = "calendarAccountsDB";
export const DB_VERSION = 1; // Increment version to trigger an upgrade

export const openCalendarDB = () =>
  openDB(DB_NAME, DB_VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
      console.log(
        `Upgrading database from version ${oldVersion} to ${newVersion}`
      );

      if (!db.objectStoreNames.contains("googleCalendarEvents")) {
        const googleStore = db.createObjectStore("googleCalendarEvents");
        googleStore.createIndex("startTime", "start.dateTime");
        googleStore.createIndex("startDate", "start.date");
        googleStore.createIndex("last_accessed", "last_accessed");
      }

      if (!db.objectStoreNames.contains("outlookCalendarEvents")) {
        const outlookStore = db.createObjectStore("outlookCalendarEvents");
        outlookStore.createIndex("start", "start");
        outlookStore.createIndex("last_accessed", "last_accessed");
      }

      if (!db.objectStoreNames.contains("appleCalendarEvents")) {
        const appleStore = db.createObjectStore("appleCalendarEvents");
        appleStore.createIndex("start", "start");
        appleStore.createIndex("last_accessed", "last_accessed");
      }
    },
  });

// A function to completely delete the database
export const deleteCalendarDB = async () => {
  console.log("Deleting calendar database");
  await indexedDB.deleteDatabase(DB_NAME);
  await indexedDB.deleteDatabase(CALENDAR_ACCOUNTS_DB_NAME);
};
