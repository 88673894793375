import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../Components/Kanban/Card";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import Droppable from "../../Components/DnDContainer/Containers/Droppable";
import SortableCard from "../../Components/DnDContainer/Containers/Sortable";
import { BsCaretRightFill, BsPlus } from "react-icons/bs";
import AddTaskCard from "../Kanban/Column/AddTaskCard";
import {
  setCreateTaskModalActive,
  updateCurrentUser,
} from "../../redux/appSlice";
import CreateCard from "../Kanban/Card/CreateCard";
import { Dropdown } from "antd";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FiMoreHorizontal } from "react-icons/fi";
import { deleteList, softListDelete } from "../../redux/tasksSlice";
import CreateList from "./Lists/CreateList";
import { BiChevronRight } from "react-icons/bi";
import { useSortable } from "@dnd-kit/sortable";
import { useHotkeys } from "react-hotkeys-hook";
import { motion, AnimatePresence } from "framer-motion";
import { isEqual } from "lodash";

function MiniList({
  order,
  indexSelected,
  manuallySelectColumn,
  navigatedViaKeyboard,
  setIndexSelected,
  hide_sidebar,
  listId,
  setNavigatedViaKeyboard,
  activelyDragging = false,
  columnSelected,
  activelyReorderingSidebar,
}) {
  const lists = useSelector((state) => state.tasks.lists || {});

  const lists_expanded = useSelector(
    (state) =>
      state.app.currentUser.lists_expanded || [
        ...(Object.keys(lists) || []),
        "brain_dump",
      ],
    (prev, next) => {
      return isEqual(prev, next);
    }
  );

  const [expanded, setExpanded] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: listId, disabled: listId === "brain_dump" });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: activelyDragging ? 0.2 : 1,
  };

  const { data: taskData } = useSelector((state) => state.tasks);

  const activeLabels = useSelector(
    (state) => state.app?.currentUser?.label_filters || [],
    (prev, next) => {
      return isEqual(prev, next);
    }
  );
  const hide_complete = useSelector(
    (state) => state.app?.currentUser?.hide_complete || false
  );

  // Function that checks if a task should be visible
  function shouldShowTask(task) {
    // If the task is complete and hide_complete is true, don't show it
    if (task.complete && hide_complete) {
      return false;
    }

    // If there are no active labels, show the task
    if (!activeLabels || activeLabels.length === 0) {
      return true;
    }

    // If there are active labels, check if task.label exists and is in activeLabels
    if (task.label) {
      return activeLabels.includes(task.label);
    } else {
      return false;
    }
  }

  const listData = useSelector(
    (state) => {
      // If listId is brain_dump, we want to return the brainDumpOrder
      if (listId === "brain_dump") {
        return {
          title: "Brain Dump",
          icon: "🧠",
          id: "brain_dump",
        };
      }
      return state.tasks.lists[listId];
    },
    (prev, next) => {
      return isEqual(prev, next);
    }
  );

  const card_layout = useSelector(
    (state) => state.app.currentUser.card_layout || "compact"
  );

  const [createActive, setCreateActive] = useState(null);

  const dispatch = useDispatch();

  const [dropdownExpanded, setDropdownExpanded] = useState(false);

  const [listEditActive, setListEditActive] = useState(false);

  useEffect(() => {
    if (createActive) {
      setIndexSelected(null);
    }
  }, [createActive]);

  useHotkeys(
    "b",
    (e) => {
      e.preventDefault();
      // Set create active to today's date
      setCreateActive(true);

      // ... set up our own saving dialog.
    },
    {
      enabled: listId === "brain_dump" && !createActive,
    },
    [createActive]
  );

  // useEffect if expanded differ
  useEffect(() => {
    if (lists_expanded.includes(listId) && !expanded) {
      setExpanded(true);
    } else if (!lists_expanded.includes(listId) && expanded) {
      setExpanded(false);
    }
  }, [lists_expanded]);

  if (!listData) {
    return null;
  }

  return (
    <div ref={setNodeRef} style={style}>
      <Droppable
        braindump={false}
        id={listId}
        hide_sidebar={hide_sidebar}
        noPadding={true}
        isCollapsed={!expanded}
        activelyDragging={activelyDragging}
        listsBeingReordered={activelyReorderingSidebar}
      >
        <div className="flex flex-col gap-0 mb-0">
          <div
            className="flex flex-row gap-1 items-center justify-between cursor-pointer select-none hover-parent py-1"
            onClick={() => {
              if (!listEditActive) {
                const newExpandedState = !expanded;

                setExpanded(newExpandedState);

                // Dispatch the update to save the new expanded state
                dispatch(
                  updateCurrentUser({
                    newValues: {
                      lists_expanded: newExpandedState
                        ? [...lists_expanded, listId]
                        : lists_expanded.filter((item) => item !== listId),
                    },
                    previousValues: {
                      lists_expanded: lists_expanded,
                    },
                  })
                );
              }
            }}
          >
            <div
              {...attributes}
              {...listeners}
              className="flex flex-row gap-1 items-center"
            >
              <motion.div
                initial={false}
                animate={{ rotate: expanded ? 90 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <BiChevronRight className="text-neutral-400 h-4 w-4" />
              </motion.div>
              <div className="text-base font-semibold dark:text-neutral-300 ph-mask">
                {listData.icon} {listData.title}
              </div>
            </div>

            <CreateList
              active={listEditActive}
              setActive={setListEditActive}
              mode={"edit"}
              listToEdit={listEditActive ? listData : null}
            />

            {listId != "brain_dump" && (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: <div>Edit list</div>,
                      icon: <PencilIcon className="h-4 w-4" />,
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation();
                        setListEditActive(true);
                      },
                    },
                    {
                      key: "2",
                      label: <div>Delete list</div>,
                      icon: <TrashIcon className="h-4 w-4" />,
                      onClick: (e) => {
                        dispatch(
                          softListDelete({
                            list: listData,
                          })
                        );

                        dispatch(
                          updateCurrentUser({
                            newValues: {
                              selectedList: "brain_dump",
                            },
                            previousValues: {
                              selectedList: listId,
                            },
                          })
                        );
                      },
                    },
                  ],
                }}
                trigger={["click"]}
                open={dropdownExpanded}
                onOpenChange={(open) => setDropdownExpanded(open)}
              >
                <FiMoreHorizontal
                  onClick={(e) => {
                    e.stopPropagation();
                    setDropdownExpanded(!dropdownExpanded);
                  }}
                  className="text-lg text-neutral-400 cursor-pointer child"
                />
              </Dropdown>
            )}
          </div>
          <AnimatePresence initial={false}>
            {expanded && !activelyDragging && (
              <motion.div
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                variants={{
                  expanded: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                className={`overflow-hidden flex flex-col gap-0 pl-[10px] ${
                  card_layout === "full" && "pl-2"
                }`}
              >
                <SortableContext
                  items={order}
                  strategy={verticalListSortingStrategy}
                  id={listId}
                >
                  {order
                    .filter(
                      (taskId) =>
                        taskData[taskId] && shouldShowTask(taskData[taskId])
                    )
                    .map((taskId, index) => {
                      return (
                        <SortableCard
                          columnId={listId}
                          id={taskId}
                          key={taskId}
                          mini={card_layout === "compact" ? true : false}
                        >
                          <Card
                            key={taskId + "_card"}
                            index={index}
                            item={taskData[taskId]}
                            activelySelected={
                              indexSelected === index &&
                              columnSelected === listId
                            }
                            manuallySelectColumn={manuallySelectColumn}
                            navigatedViaKeyboard={navigatedViaKeyboard}
                            columnId={listId}
                            setIndexSelected={setIndexSelected}
                            mini={card_layout === "compact" ? true : false}
                          />
                        </SortableCard>
                      );
                    })}
                </SortableContext>
                {createActive && (
                  <CreateCard
                    setCreateActive={setCreateActive}
                    setNavigatedViaKeyboard={setNavigatedViaKeyboard}
                    listId={listId}
                  />
                )}
                {!createActive && (
                  <div
                    onClick={() => {
                      /*  dispatch(
                  setCreateTaskModalActive({
                    active: true,
                    date: null,
                    start_date: null,
                    listId: listId,
                  })
                ); */

                      setCreateActive(true);
                    }}
                    className={`px-2 text-neutral-400 dark:text-neutral-500 hover:text-neutral-800 dark:hover:text-neutral-300 flex flex-row items-center gap-1 py-1 border border-transparent rounded-md cursor-pointer ${
                      card_layout === "full" && "py-0 pl-2 mb-1"
                    }`}
                  >
                    <BsPlus className="h-5 w-5" />
                    <span>New Task</span>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Droppable>
    </div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  return (
    prev.order.length === next.order.length &&
    prev.order.every((taskId, index) => taskId === next.order[index]) &&
    prev.hide_sidebar === next.hide_sidebar &&
    prev.indexSelected === next.indexSelected &&
    prev.navigatedViaKeyboard === next.navigatedViaKeyboard &&
    prev.listId === next.listId &&
    prev.expandedInitial === next.expandedInitial &&
    prev.activelyDragging === next.activelyDragging &&
    prev.columnSelected === next.columnSelected &&
    prev.activelyReorderingSidebar === next.activelyReorderingSidebar
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(MiniList, areEqual);
