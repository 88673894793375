import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTimeMinAndTimeMax } from "./calendarUtils";
import { auth } from "../../firebase";
import axios from "axios";
import { outlookServerUrl } from "../../utils";
import { setToastVisible } from "../appSlice";
import {
  deleteOutlookEventFromIndexedDB,
  getOutlookCalendarEventsFromDB,
  updateOutlookEventInIndexedDB,
} from "./calendarIndexDBUtils";
import { openCalendarDB } from "./indexDbSchema";

export const fetchOutlookCalendarEvents = createAsyncThunk(
  "calendar/fetchOutlookCalendarEvents",
  async (signal, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;

    const calendarDate = getState().tasks.calendarDate;

    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const activeCalendarsJson = JSON.stringify(active_calendars);

    const idToken = await auth.currentUser.getIdToken(true);

    try {
      const response = await axios.get(`${outlookServerUrl}/getOutlookEvents`, {
        params: {
          timeMin: timeMin,
          timeMax: timeMax,
          active_calendars: activeCalendarsJson,
          idToken: idToken,
        },
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "true",
        },
        signal, // Add the signal here
      });

      if (signal.aborted) throw new Error("Cancelled");

      if (response.data && response.data.events?.length > 0) {
        const newEvents = response.data.events.map((event) => ({
          id: event?.id,
          title: event?.title,
          description: event?.bodyPreview,
          start: event?.start,
          end: event?.end,
          calendar: event?.calendar,
          location: event?.location?.displayName,
          attendees: event?.attendees,
          etag: event?.etag,
          calendarAccount: event?.calendarAccount,
          calendarId: event?.calendarId,
          accountId: event?.accountId,
          allDay: event?.isAllDay,
          extendedProps: {
            type: "outlook",
            url: event?.webLink,
            data: event,
            recurring: event?.isRecurring,
            ellie_task_id: event?.ellie_task_id,
          },
        }));

        try {
          // Store the new events in IndexedDB
          const db = await openCalendarDB();
          const tx = db.transaction("outlookCalendarEvents", "readwrite");
          const store = tx.objectStore("outlookCalendarEvents");

          const existingEvents = await getOutlookCalendarEventsFromDB(
            timeMin,
            timeMax,
            active_calendars,
            tx,
            store
          );

          for (const existingEvent of existingEvents) {
            if (!newEvents.find((e) => e.id === existingEvent.id)) {
              await store.delete(
                `/event/${existingEvent.accountId}/${existingEvent.calendarId}/${existingEvent.id}`
              );
            }
          }

          for (const event of newEvents) {
            const compositeKey = `/event/${event.accountId}/${event.calendarId}/${event.id}`;
            await store.put(
              { ...event, last_accessed: new Date().toISOString() },
              compositeKey
            );
          }

          await tx.done;
        } catch (error) {
          console.error(
            `Error storing Google Calendar events in IndexedDB:`,
            error
          );

          dispatch(
            setToastVisible({
              toastType: "error",
              message: `Something went wrong storing Google Calendar events in IndexedDB. Please contact support ${error}`,
            })
          );
        }

        const newOutlookEvents = newEvents.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});

        return fulfillWithValue(newOutlookEvents);
      } else {
        return fulfillWithValue({});
      }
    } catch (error) {
      if (axios.isCancel(error) || error.message === "Cancelled") {
        throw new Error("Cancelled");
      }
      console.log(error);
      dispatch(
        setToastVisible({
          toastType: "error",
          message: `Something went wrong talking to Outlook calendar. Please contact support ${error}`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateOutlookCalendarEvent = createAsyncThunk(
  "calendar/updateOutlookCalendarEvent",
  async (
    { eventId, newData, sendUpdates },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const userId = getState().app.uid;

    // Get the current event from the calendar slice
    const outlookEvent = getState().calendar.outlookEvents[eventId];

    try {
      const idToken = await auth.currentUser.getIdToken(true);

      const response = await axios.post(
        `${outlookServerUrl}/updateOutlookEvent`,
        newData,
        {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          params: {
            accountId: outlookEvent.accountId,
            eventId: outlookEvent.id,
            calendarId: outlookEvent.calendarId,
            idToken: idToken,
            // sendUpdates: sendUpdates,
          },
        }
      );

      if (response.status === 200) {
        console.log("Successfully updated outlook event");

        console.log("newData", newData);
        console.log("outlookEvent", outlookEvent);

        // Update the event in IndexedDB
        await updateOutlookEventInIndexedDB(
          outlookEvent.id,
          outlookEvent.calendarId,
          outlookEvent.accountId,
          newData
        );

        return fulfillWithValue({ ...outlookEvent, ...newData });
      } else {
        console.log("Error updating outlook event");
        return rejectWithValue(outlookEvent);
      }
    } catch (error) {
      return rejectWithValue(outlookEvent);
    }
  }
);

export const deleteOutlookCalendarEvent = createAsyncThunk(
  "calendar/deleteOutlookCalendarEvent",
  async (
    { outlookEvent },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    console.log(" dispatch: Deleting outlook event");

    // Get the current event from the calendar slice
    const outlookEventId = outlookEvent.id;

    // const outlookEvents = getState().calendar.googleEvents[outlookEventId];

    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.post(
        `${outlookServerUrl}/deleteOutlookEvent`,
        outlookEvent,
        {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
          params: {
            accountId: outlookEvent.accountId,
            eventId: outlookEventId,
            idToken: idToken,
          },
        }
      );

      console.log("outlookEvent", outlookEvent);
      if (response.status === 200) {
        console.log("Successfully deleted outlook event");

        // Delete the event from IndexedDB
        await deleteOutlookEventFromIndexedDB(
          outlookEvent.id,
          outlookEvent.calendarId,
          outlookEvent.accountId
        );

        return fulfillWithValue(outlookEventId);
      } else {
        console.log("Error deleting outlook event");
        return rejectWithValue(outlookEventId);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadCachedOutlookEvents = createAsyncThunk(
  "calendar/loadCachedOutlookEvents",
  async (_, { getState }) => {
    const {
      active_calendars = {},
      active_calendar_type = "week",
      mode = "kanban",
    } = getState().app.currentUser;

    const calendarDate = getState().tasks.calendarDate;
    const { timeMin, timeMax } = getTimeMinAndTimeMax(
      active_calendar_type,
      calendarDate,
      mode
    );

    const cachedEvents = await getOutlookCalendarEventsFromDB(
      timeMin,
      timeMax,
      active_calendars
    );

    if (cachedEvents.length > 0) {
      const outlookEvents = cachedEvents.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});

      return { outlookEvents };
    } else {
      return {};
    }
  }
);
