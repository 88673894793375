import React from "react";
import { Toaster, toast } from "sonner";
import { useSelector, useDispatch } from "react-redux";
import { setToastVisible } from "../../redux/appSlice";

const ToastComponent = () => {
  const toastVisible = useSelector((state) => state.app.toastVisible);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (toastVisible) {
      const { type, message } = toastVisible;
      toast[type](message, {
        duration: 5000,
        onDismiss: () => {
          dispatch(setToastVisible(null));
        },
        onAutoClose: () => {
          dispatch(setToastVisible(null));
        },
        position: "top-right",
        richColors: true,
      });
    }
  }, [toastVisible, dispatch]);

  return <div> </div>;
};

export default ToastComponent;
