import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Kanban.css";

import Column from "./Column";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  loadNextDates,
  loadPreviousDates,
  selectNextColumn,
  selectPreviousColumn,
  refreshKabanCursor,
  updateCurrentUser,
} from "../../redux/appSlice";

import { useHotkeys } from "react-hotkeys-hook";

import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import TopbarActions from "./TopbarActions";
import _ from "lodash";
import { changeCalendarDate } from "../../redux/tasksSlice";
import Timer from "../Timer";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { isMac } from "../../utils";
import FloatingTimer from "../Generics/FloatingTimer";
import { useLocation } from "react-router-dom";

function Kanban({ isDragging, taskOrder }) {
  const kanbanRef = useRef();
  const dispatch = useDispatch();

  const location = useLocation();


  const isWeekday = (date) => {
    // Create dayjs date from string "YYYY-MM-DD"
    const dayOfWeek = dayjs(date).day();

    return dayOfWeek !== 0 && dayOfWeek !== 6;
  };

// ... existing code ...

const calendar_show_weekends = useSelector(
  (state) => state.app.currentUser.calendar_show_weekends ?? true
);

const dates = useSelector((state) => {
  const dates = state.app.dates;
  return calendar_show_weekends ? dates : dates.filter(isWeekday);
});

// ... existing code ...

  const active_calendar_type = useSelector(
    (state) => state.app.currentUser.active_calendar_type ?? "week"
  );

  const mode = useSelector((state) => state.app.mode ?? "kanban");

  const floating_timer_enabled = useSelector(
    (state) => state.app.currentUser.floating_timer_enabled ?? false
  );

  // Default columnSelected to the index of today's column in taskOrder

  const [navigatedViaKeyboard, setNavigatedViaKeyboard] = useState(false);

  const kanbanRefreshCursor = useSelector(
    (state) => state.app.kanbanRefreshCursor
  );

  useEffect(() => {
    if (kanbanRefreshCursor && kanbanRef.current) {
      var index = dates.indexOf(kanbanRefreshCursor);
      kanbanRef.current.scrollLeft = 295 * index - 15;

      dispatch(refreshKabanCursor(null));
    }
  }, [kanbanRefreshCursor]);

  // If location path changes
  useEffect(() => {
    if (location.pathname === "/") {
      // Scroll to today
      scrollToColumn(dayjs().format("YYYY-MM-DD"));
    }
  }, [location.pathname]);

  function scrollToColumn(date) {
    if (kanbanRef.current) {
      var index = dates.indexOf(date);

      kanbanRef.current.scrollLeft = 295 * index - 15;
    }
  }

  const [isDraggingWithMouse, setIsDraggingWithMouse] = useState(false);

  // Create event handlers so a user can drag the kanban board with their mouse to scroll left and right
  // useCallback is used to prevent the function from being recreated on every render
  const handleMouseDown = useCallback((e) => {
    // Only run the function if the user is dragging on the kanban board or class is "draggable-container"
    if (
      e.target === kanbanRef.current ||
      e.target.className === "draggable-container "
    ) {
      // Set the isDraggingWithMouse state to true
      setIsDraggingWithMouse(true);

      // Prevent scrolling on the y-axix when the user is dragging
      e.preventDefault();

      // Set the initial position of the mouse
      var initialX = e.clientX;
      var initialScrollLeft = kanbanRef.current.scrollLeft;

      // Create a function to handle the mouse movement
      const handleMouseMove = (e) => {
        const daysPerPeriod = calendar_show_weekends ? 7 : 5;

        // Calculate the distance the mouse has moved since the initial position
        const distanceX = e.clientX - initialX;

        // Scroll the kanban board to the new position
        kanbanRef.current.scrollLeft = initialScrollLeft - distanceX;

        const scrollLeftRoundingUp = Math.ceil(
          kanbanRef.current.scrollLeft + kanbanRef.current.offsetWidth
        );

        if (scrollLeftRoundingUp >= kanbanRef.current.scrollWidth) {
          dispatch(
            loadNextDates({
              type: active_calendar_type,
              mode: mode,
            })
          );
          kanbanRef.current.scrollLeft -= 295 * daysPerPeriod - 30;

          initialScrollLeft = kanbanRef.current.scrollLeft;
          initialX = e.clientX;
        }

        if (kanbanRef.current.scrollLeft === 0) {
          dispatch(
            loadPreviousDates({
              type: active_calendar_type,
              mode: mode,
            })
          );
          kanbanRef.current.scrollLeft += 295 * daysPerPeriod - 30;

          initialScrollLeft = kanbanRef.current.scrollLeft;
          initialX = e.clientX;
        }
      };

      // Add the mousemove event listener
      kanbanRef.current.addEventListener("mousemove", handleMouseMove);

      // Remove the mousemove event listener when the user releases the mouse button to the document
      document.addEventListener(
        "mouseup",
        () => {
          setIsDraggingWithMouse(false);
          kanbanRef.current.removeEventListener("mousemove", handleMouseMove);
        },
        { once: true }
      );
    }
  }, []);

  // Add the mousedown event listener to the kanban board
  useEffect(() => {
    kanbanRef.current.addEventListener("mousedown", handleMouseDown);

    // Remove the mousedown event listener when the component unmounts
    return () => {
      if (kanbanRef.current) {
        kanbanRef.current.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [handleMouseDown]);


  return (
    <div className="kanban-board">
      <TopbarActions />
      <div
        className="kanban-body"
        style={{
          overflowX: isDragging ? "hidden" : "auto",
        }}
        ref={kanbanRef}
        onMouseMove={() => {
          if (navigatedViaKeyboard) {
            setNavigatedViaKeyboard(false);
          }
        }}
        onScroll={(event) => {
          const daysPerPeriod = calendar_show_weekends ? 7 : 5;

          // Prevent scrolling on the x-axix when the user is dragging
          const scrollLeftRoundingUp = Math.ceil(
            event.target.scrollLeft + event.target.offsetWidth
          );

          if (!isDragging && !isDraggingWithMouse) {
            if (scrollLeftRoundingUp >= event.target.scrollWidth) {
              dispatch(
                loadNextDates({
                  type: active_calendar_type,
                  mode: mode,
                })
              );
              kanbanRef.current.scrollLeft -= 295 * daysPerPeriod - 30;
            }

            if (event.target.scrollLeft === 0) {
              dispatch(
                loadPreviousDates({
                  type: active_calendar_type,
                  mode: mode,
                })
              );
              kanbanRef.current.scrollLeft += 295 * daysPerPeriod - 30;
            }
          }
        }}
      >
        {dates.map((date, index) => {
          if (taskOrder && taskOrder[date]) {
            return (
              <Column
                key={"column_" + date}
                order={taskOrder[date].order}
                date={date}
                scrollToColumn={scrollToColumn}
                navigatedViaKeyboard={navigatedViaKeyboard}
                setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              />
            );
          } else {
            return (
              <Column
                key={"column_" + date}
                order={[]}
                date={date}
                scrollToColumn={scrollToColumn}
                navigatedViaKeyboard={navigatedViaKeyboard}
                setNavigatedViaKeyboard={setNavigatedViaKeyboard}
              />
            );
          }
        })}
      </div>

      {(!isDesktopApp() || !floating_timer_enabled) && <Timer />}
    </div>
  );
}

// Custom areEqual function to check if we should re-render
function areEqual(prev, next) {
  // Check if the taskOrder is the same, using lodash _.isEqual
  return (
    _.isEqual(prev.taskOrder, next.taskOrder) &&
    prev.isDragging === next.isDragging
  );
}

export default React.memo(Kanban, areEqual);
