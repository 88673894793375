import "./RecurringInput.css";
import React, { useEffect, useState } from "react";
import { TiArrowRepeat } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Tooltip } from "antd";

import {
  getRecurrenceText,
  months,
  ordinal_suffix_of,
  weekdays,
} from "../../../../../utils";

import RecurringTaskMenu from "./RecurringTaskMenu";
import { RRule } from "rrule";
import moment from "moment";
import { addMinutes } from "date-fns";
import {
  createRecurringTask,
  stopRecurringTask,
  updateIncompleteInstances,
} from "../../../../../redux/recurringTasksSlice";
import {
  updateRecurringTask,
  updateTask,
} from "../../../../../redux/tasksSlice";

export default function RecurringInput({
  item,
  recurringTaskLocal,
  updateRecurringLocal,
  creation = false,
  recurringTaskMenuOpen,
  setRecurringTaskMenuOpen,
  modalMode = false,
  updateLocalTask,
}) {
  const dispatch = useDispatch();

  const [toolTipVisible, setToolTipVisible] = useState(false);

  const time_format = useSelector((state) => state.app?.currentUser?.time_format || "12_hour");

  const recurringTask = useSelector(
    (state) => state.tasks.recurringTasks[item?.recurring_id]
  );

  const recurringTasks = useSelector((state) => state.tasks.recurringTasks);

  const [recurringTaskOptions, setRecurringTaskOptions] = useState([]);
  const [recurrenceText, setRecurrenceText] = useState("Does not repeat");

  const [isCustom, setIsCustom] = useState(false);

  const [recurringTaskEditable, setRecurringTaskEditable] = useState(null);
  const [specificTimeEditable, setSpecificTimeEditable] = useState(null);
  const [skipSave, setSkipSave] = useState(false);
  const [stopRecurrence, setStopRecurrence] = useState(false);
  const [updateIncompleteWithNewTask, setUpdateIncompleteWithNewTask] =
    useState(false);

  useEffect(() => {
    function generateRecurringOptions() {
      // Get the date of the task
      const tzOffset = new Date().getTimezoneOffset();

      // local Monday to utc Monday
      const toRRuleInput = (date) => {
        return addMinutes(date, -tzOffset); // using date-fn lib here
      };

      var date = item.date && item.date.toDate ? item.date.toDate() : item.date;

      // convert date to string with z at the end
      var dateStr = moment(date).format("YYYY-MM-DDTHH:mm:ss") + "Z";

      // date = new Date(dateStr);

      if (!date) {
        return null;
      }


      // Get the day of the week [0-6]
      const dayOfWeek = date.getDay();

      // dayOfWeekWhere0IsMonday
      const dayOfWeekWhere0IsMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      // Get the day of the month [1-31]
      const dayOfMonth = date.getDate();

      // Get the ordinal number of the week of the month [1-5]
      var weekOfMonth = Math.ceil(dayOfMonth / 7);

      // If the day is the last day of the month, set the ordinal number to -1
      if (dayOfMonth === getLastDayOccurence(date, dayOfWeek).getDate()) {
        weekOfMonth = -1;
      }

      const dailyRule = new RRule({
        freq: RRule.DAILY,
        interval: 1,
        dtstart: toRRuleInput(date),
      });

      const weeklyRule = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        dtstart: toRRuleInput(date),
        byweekday: [dayOfWeekWhere0IsMonday],
      });

      const biweeklyRule = new RRule({
        freq: RRule.WEEKLY,
        interval: 2,
        dtstart: toRRuleInput(date),
        byweekday: [dayOfWeekWhere0IsMonday],
      });

      const weekdayRule = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        dtstart: toRRuleInput(date),
        byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
      });

      const weekendRule = new RRule({
        freq: RRule.WEEKLY,
        interval: 1,
        dtstart: toRRuleInput(date),
        byweekday: [RRule.SA, RRule.SU],
      });

      const monthlyRule = new RRule({
        freq: RRule.MONTHLY,
        dtstart: toRRuleInput(date),
        interval: 1,
        byweekday: [dayOfWeekWhere0IsMonday],
        bysetpos: [weekOfMonth],
      });

      const yearlyRule = new RRule({
        freq: RRule.YEARLY,
        dtstart: toRRuleInput(date),
        interval: 1,
        bymonth: date.getMonth() + 1,
        bymonthday: dayOfMonth,
      });

      var options = [
        {
          title: "Does not repeat",
          recurrenceRule: null,
          subTitle: "",
        },
        {
          title: "Every day",
          recurrenceRule: dailyRule,
          subTitle: "",
        },
        {
          title: "Every weekday",
          subTitle: "(Mon - Fri)",
          recurrenceRule: weekdayRule,
        },
        {
          title: "Every week",
          subTitle: `(on ${weekdays[dayOfWeek]})`,
          recurrenceRule: weeklyRule,
        },
        {
          title: "Every 2 weeks",
          subTitle: `(on ${weekdays[dayOfWeek]})`,
          recurrenceRule: biweeklyRule,
        },
        {
          title: "Every month",
          subTitle: `(on the ${
            weekOfMonth == -1 ? "last" : ordinal_suffix_of(weekOfMonth)
          } ${weekdays[dayOfWeek]})`,
          recurrenceRule: monthlyRule,
        },
        {
          title: "Every year",
          subTitle: `(on ${months[date.getMonth()]} ${ordinal_suffix_of(
            dayOfMonth
          )})`,
          recurrenceRule: yearlyRule,
        },
      ];

      // If the date is on the weekend, replace 3rd option with "Every weekend"
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        options[2] = {
          title: "Every weekend day",
          subTitle: "(Sat & Sun)",
          recurrenceRule: weekendRule,
        };
      }

      return options;
    }

    const options = generateRecurringOptions();

    setRecurringTaskOptions(options);
  }, [item.date]);

  function isCustomRRule(rrule, options) {
    if (!rrule) {
      return false;
    }


    // Check if its a valid rrule
    try {
      var rruleObject = RRule.fromString(rrule);

      return !options.some((option) => {
        const optionRRule = option.recurrenceRule;
        return optionRRule && rruleObject.toText() === optionRRule.toText();
      });
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    if (recurringTaskOptions && recurringTaskEditable) {
      var isCustom = false;

      isCustom = isCustomRRule(
        recurringTaskEditable.rrule,
        recurringTaskOptions
      );

      const recurrenceText = getRecurrenceText({
        recurringTask: recurringTaskEditable,
        isCustomRRule: isCustom,
        time_format: time_format,
      });

      setIsCustom(isCustom);

      setRecurrenceText(recurrenceText);
    } else {
      setRecurrenceText("Does not repeat");

      setIsCustom(false);
    }
  }, [recurringTaskOptions, recurringTaskEditable, creation, time_format]);

  function saveChanges() {
    // If this is creation, we need to create a new recurring task
    // We update the recurring local with the new recurring task
    // If we made time changes, we need to also update the local task time
    if (creation && recurringTaskEditable) {
      if (
        recurringTaskEditable?.task_template?.start !== specificTimeEditable
      ) {
        setRecurringTaskEditable({
          ...recurringTaskEditable,
          task_template: {
            ...recurringTaskEditable.task_template,
            start: specificTimeEditable,
          },
        });

        let newData = {
          start: specificTimeEditable,
        };

        if (specificTimeEditable) {
          newData.date = specificTimeEditable;
        } else {
          // Make it the end of the date
          newData.date = moment(item.date).startOf("day").toDate();
        }

        updateLocalTask(newData);
      }

      updateRecurringLocal(recurringTaskEditable);
    }

    if (creation && !recurringTaskEditable) {
      updateRecurringLocal(null);
    }

    // We are updating a task to be recurring that was previously not recurring
    if (!creation && !recurringTask && recurringTaskEditable) {
   
      var recurringTaskEditableTemp = recurringTaskEditable;
      if (
        recurringTaskEditable?.task_template?.start !== specificTimeEditable
      ) {
        recurringTaskEditableTemp = {
          ...recurringTaskEditable,
          task_template: {
            ...recurringTaskEditable.task_template,
            start: specificTimeEditable,
          },
        };
      }


      dispatch(createRecurringTask({ recurringTask: recurringTaskEditableTemp }));

      var newData = {
        recurring: true,
        recurring_id: recurringTaskEditableTemp.id,
      };

      // If we made time changes, we need to also update the local task time
      newData.start = specificTimeEditable;

      // If there is a date, we need to update the date
      if (specificTimeEditable) {
        newData.date = specificTimeEditable;
      }

      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: newData,
        })
      );
    }

    // We are udpating the recurrince of a task that was already recurring
    if (!creation && recurringTask && recurringTaskEditable) {
      if (updateIncompleteWithNewTask) {
        dispatch(
          updateIncompleteInstances({
            recurringTask: recurringTaskEditable,
            taskToMatch: updateIncompleteWithNewTask,
            includeTask: true,
          })
        );
        setUpdateIncompleteWithNewTask(false);
      } else {
        // Check if recurringTask and recurringTaskEditable are different
        if (
          JSON.stringify(recurringTask) !==
          JSON.stringify(recurringTaskEditable)
        ) {
          dispatch(
            updateRecurringTask({
              recurringTaskId: recurringTask.id,
              currentRecurringTask: recurringTask,
              newData: recurringTaskEditable,
            })
          );
        }
      }
    }

    // We are updating a task to be non-recurring that was previously recurring
    if (!creation && recurringTask && stopRecurrence) {
      // We need to stopp recurring
      dispatch(
        stopRecurringTask({
          recurringTask: recurringTask,
          taskToStop: item,
        })
      );

      setStopRecurrence(false);
    }
  }

  useEffect(() => {
    // LEt's set the recurringTaskEditable depending on creation
    if (creation) {
      setRecurringTaskEditable(recurringTaskLocal);

      if (recurringTaskLocal?.task_template?.start) {
        setSpecificTimeEditable(recurringTaskLocal.task_template?.start);
      }
    } else {
      setRecurringTaskEditable(recurringTask);
      if (recurringTask?.task_template?.start) {
        setSpecificTimeEditable(recurringTask.task_template?.start);
      }
    }
  }, [creation, recurringTask, recurringTaskLocal]);

  const [customRecurrenceEditorOpen, setCustomRecurrenceEditorOpen] =
    useState(false);

  const isRecurring = item?.recurring && recurringTask;

  return (
    <Popover
      open={recurringTaskMenuOpen}
      onOpenChange={(visible) => {
        if (!customRecurrenceEditorOpen) {
          if (item?.date) {
            setRecurringTaskMenuOpen(visible);
          }

          // If the menu is closed, let's make the changes
          if (!visible) {
            if (!skipSave) {
              saveChanges();
            } else {
              setSkipSave(false);
            }
          }
        }
      }}
      destroyTooltipOnHide={false}
      title={null}
      content={
        recurringTaskMenuOpen && (
          <RecurringTaskMenu
            task={item}
            setRecurringTaskMenuOpen={setRecurringTaskMenuOpen}
            recurringTaskLocal={recurringTaskLocal}
            updateRecurringLocal={updateRecurringLocal}
            creation={creation}
            updateLocalTask={updateLocalTask}
            options={recurringTaskOptions}
            recurrenceText={recurrenceText}
            isCustom={isCustom}
            recurringTaskEditable={recurringTaskEditable}
            setRecurringTaskEditable={setRecurringTaskEditable}
            specificTimeEditable={specificTimeEditable}
            setSpecificTimeEditable={setSpecificTimeEditable}
            closeWithoutSaving={() => {
              setSkipSave(true);
              setRecurringTaskMenuOpen(false);
            }}
            stopRecurrence={stopRecurrence}
            setStopRecurrence={setStopRecurrence}
            setUpdateIncompleteWithNewTask={setUpdateIncompleteWithNewTask}
            customRecurrenceEditorOpen={customRecurrenceEditorOpen}
            setCustomRecurrenceEditorOpen={setCustomRecurrenceEditorOpen}
          />
        )
      }
      placement="rightTop"
      trigger="click"
      zIndex={1001}
    >
      <Tooltip
        open={toolTipVisible}
        onOpenChange={(visible) => {
          if (!item?.date) {
            setToolTipVisible(visible);
          }
        }}
        placement="bottomRight"
        title={"Tasks in the brain dump cannot be recurring"}
      >
        {modalMode ? (
          <div
            className={`mcf-button${
              recurrenceText == "Does not repeat" ? " empty" : ""
            }`}
            onClick={() => {
              if (item?.date) {
                setRecurringTaskMenuOpen(true);
              }
            }}
          >
            {recurrenceText}
          </div>
        ) : (
          <div
            className={`subtask-button-container ${
              isRecurring || creation ? "visible" : ""
            }`}
            data-no-dnd="true"
            onClick={() => {
              if (item?.date) {
                setRecurringTaskMenuOpen(true);
              }
            }}
          >
            <TiArrowRepeat className="subtask-button repeat" />
          </div>
        )}
      </Tooltip>
    </Popover>
  );
}

// Function to get the last instance of a month
function getLastDayOccurence(date, dayOfWeek) {
  var result = moment(date).endOf("month");

  while (result.day() !== dayOfWeek) {
    result.subtract(1, "day");
  }

  return result.toDate();
}
