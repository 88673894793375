import "./LabelSelector.css";
import { useState, useRef, useEffect } from "react";
import { Popover } from "antd";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

import { useSelector, useDispatch } from "react-redux";

import CreateLabel from "./CreateLabel";
import {
  toggleLabelManager,
  updateCurrentUser,
} from "../../../../../redux/appSlice";
import { useHotkeys } from "react-hotkeys-hook";

import { updateTask } from "../../../../../redux/tasksSlice";
import { VscSparkleFilled } from "react-icons/vsc";
import { useMediaQuery } from "react-responsive";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { cloneDeep, isEqual } from "lodash";
import { IoSparkles } from "react-icons/io5";
export default function LabelSelector({
  item,
  labelPickerActive,
  setLabelPickerActive,
  updateLabelLocal,
  label,
  disabled,
  buttonMode = false,
  alwaysVisible = false,
}) {
  const { data: labels, loading } = useSelector((state) => state.labels);
  const recently_used_labels = useSelector(
    (state) => state.app.currentUser.recently_used_labels ?? [],
    (prev, next) => {
      return isEqual(prev, next);
    }
  );

  function updateLabel(newValue) {
    if (updateLabelLocal) {
      updateLabelLocal(newValue);
    } else {
      dispatch(
        updateTask({
          taskId: item.id,
          currentTask: item,
          newData: {
            label: newValue,
          },
        })
      );
    }
  }

  const addRecentLabel = (labelOptionId) => {
    var recentLabelsClone = cloneDeep(recently_used_labels);
    // check if the label is already in the array
    var index = recentLabelsClone.indexOf(labelOptionId);
    if (index > -1) {
      // remove the label from the array
      recentLabelsClone.splice(index, 1);
      // add the label to the beginning of the array
      recentLabelsClone.unshift(labelOptionId);
    } else {
      // if it doesn't add it to the beginning of the array anyway
      recentLabelsClone.unshift(labelOptionId);
    }

    // update current user
    dispatch(
      updateCurrentUser({
        newValues: { recently_used_labels: recentLabelsClone },
        previousValues: { recently_used_labels: recently_used_labels },
      })
    );
  };

  var firstThreeRecentLabelIds = recently_used_labels.slice(0, 3);

  const recentLabels = Object.values(labels)
    .filter((label) => {
      return firstThreeRecentLabelIds.includes(label.id);
    })
    .sort(
      (a, b) =>
        recently_used_labels.indexOf(a.id) - recently_used_labels.indexOf(b.id)
    );

  const dispatch = useDispatch();

  return (
    <>
      <Popover
        open={disabled ? false : labelPickerActive}
        onOpenChange={(visible) => {
          setLabelPickerActive(visible);
        }}
        //   destroyTooltipOnHide={false}
        title={null}
        content={
          labelPickerActive && !disabled ? (
            <LabelSelectorContent
              label={label}
              updateLabel={updateLabel}
              addRecentLabel={addRecentLabel}
              recentLabels={recentLabels}
              labelPickerActive={labelPickerActive}
              setLabelPickerActive={setLabelPickerActive}
              labels={labels}
              loading={loading}
            />
          ) : null
        }
        placement="bottom"
        trigger="click"
        disabled={true}
        destroyTooltipOnHide={true}
      >
        {buttonMode ? (
          <>
            {(!label || !labels[label]) && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setLabelPickerActive(!labelPickerActive);
                }}
                className="mcf-button empty"
              >
                Select a label
              </div>
            )}
            {label && labels[label] && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  setLabelPickerActive(!labelPickerActive);
                }}
                className="mcf-button ph-mask"
                style={{ color: labels[label].color }}
              >
                <div
                  className="label-box"
                  style={{ backgroundColor: labels[label].color }}
                />
                {labels[label].name}
              </div>
            )}
          </>
        ) : (
          <div
            onClick={(event) => {
              event.stopPropagation();
              setLabelPickerActive(!labelPickerActive);
            }}
            data-no-dnd="true"
            style={{ maxWidth: "107px" }}
          >
            {(!label || !labels[label]) && (
              <div
                className={`card-no-label ${
                  labelPickerActive || alwaysVisible ? "visible" : ""
                }`}
              >
                Select Label
              </div>
            )}

            {label && labels[label] && (
              <div className="card-label ph-mask">
                <div
                  className="label-box"
                  style={{ backgroundColor: labels[label].color }}
                />
                <span>{labels[label].name}</span>
              </div>
            )}
          </div>
        )}
      </Popover>
    </>
  );
}

export function LabelSelectorContent({
  label,
  updateLabel,
  addRecentLabel,
  recentLabels,
  labelPickerActive,
  setLabelPickerActive,
  labels,
  loading,
}) {
  const searchRef = useRef();

  const [createLabelActive, setCreateLabelActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const [indexSelected, setIndexSelected] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useHotkeys(
    "up",
    (e) => {
      e.preventDefault();
      if (indexSelected > 0) {
        setIndexSelected(indexSelected - 1);
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "enter",
    (e) => {
      e.preventDefault();

      var labelCount =
        Object.values(labels)
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .filter(function (label) {
            if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            }
            return false;
          }).length || 0;

      if (
        (searchTerm !== "" && labelCount === indexSelected) ||
        (searchTerm === "" &&
          labelCount + (recentLabels ? recentLabels.length : 0) ===
            indexSelected)
      ) {
        setCreateLabelActive(true);
      } else if (
        (searchTerm !== "" && labelCount + 1 === indexSelected) ||
        (searchTerm === "" &&
          labelCount + 1 + (recentLabels ? recentLabels.length : 0) ===
            indexSelected)
      ) {
        dispatch(toggleLabelManager());
        setLabelPickerActive(false);
      } else {
        var labelOption;
        // if there's recent labels and no search term, let's factor in the recent labels
        if (recentLabels && searchTerm === "") {
          var combinedOptions = [
            ...recentLabels,
            ...Object.values(labels).sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            ),
          ];
          labelOption = combinedOptions[indexSelected];
        } else {
          //else just use the index selected
          labelOption = Object.values(labels)
            .sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            )
            .filter(function (label) {
              if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
              }

              return false;
            })[indexSelected];
        }

        if (labelOption && labelOption.id) {
          addRecentLabel(labelOption.id);
          updateLabel(labelOption.id);
          setLabelPickerActive(false);
        }
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive && !createLabelActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();

      setLabelPickerActive(false);
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useHotkeys(
    "down",
    (e) => {
      e.preventDefault();
      if (
        indexSelected <
        Object.values(labels)
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
          .filter(function (label) {
            if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            }
            return false;
          }).length +
          1 +
          (searchTerm === "" && recentLabels ? recentLabels.length : 0)
      ) {
        setIndexSelected(indexSelected + 1);
      }
    },
    {
      enableOnTags: ["INPUT"],
      enabled: labelPickerActive,
    },
    [indexSelected]
  );

  useEffect(() => {
    if (!isMobile && searchRef?.current && labelPickerActive) {
      searchRef.current.focus();
    }
  }, [isMobile, labelPickerActive]);

  var labelCount =
    Object.values(labels)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .filter(function (label) {
        if (label.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return true;
        }
        return false;
      }).length || 0;

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="label-selector-container"
      data-no-dnd="true"
    >
      <div className="label-selector-search">
        <MagnifyingGlassIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setIndexSelected(0);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
          ref={searchRef}
          autoFocus={!isMobile}
        />
      </div>
      <div className="label-selector-options ph-mask">
        {loading && <div>Loading Labels</div>}

        {!createLabelActive && !loading && labels && (
          <div>
            {!searchTerm && recentLabels.length > 0 && (
              <>
                <div className="text-xs text-neutral-400 font-semibold pl-4 my-1">
                  Recently used
                </div>
                {recentLabels.map((labelOption, index) => {
                  return (
                    <Label
                      label={label}
                      key={labelOption.id + "_recent_label-option"}
                      labelOption={labelOption}
                      index={index}
                      indexSelected={indexSelected}
                      updateLabel={updateLabel}
                      addRecentLabel={addRecentLabel}
                      setLabelPickerActive={setLabelPickerActive}
                    />
                  );
                })}
              </>
            )}

            <div className="text-xs text-neutral-400 font-semibold pl-4 my-1">
              All labels
            </div>
            {Object.values(labels)
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              .filter(function (label) {
                if (
                  label.name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
              .map((labelOption, index) => {
                return (
                  <Label
                    label={label}
                    key={labelOption.id + "_label-option"}
                    labelOption={labelOption}
                    index={
                      searchTerm === ""
                        ? index + (recentLabels ? recentLabels.length : 0)
                        : index
                    }
                    indexSelected={indexSelected}
                    updateLabel={updateLabel}
                    addRecentLabel={addRecentLabel}
                    setLabelPickerActive={setLabelPickerActive}
                  />
                );
              })}
            <div
              className={`create-label-button-container ${
                searchTerm === ""
                  ? labelCount + recentLabels.length === indexSelected
                    ? " selected"
                    : ""
                  : labelCount === indexSelected
                  ? " selected"
                  : ""
              }`}
              onClick={() => setCreateLabelActive(true)}
            >
              <PlusCircleIcon className="create-label-icon" />
              Create {searchTerm ? `"${searchTerm}"` : "Label"}
            </div>

            <div
              className={`create-label-button-container ${
                searchTerm === ""
                  ? labelCount + recentLabels.length + 1 === indexSelected
                    ? " selected"
                    : ""
                  : labelCount + 1 === indexSelected
                  ? " selected"
                  : ""
              }`}
              onClick={() => {
                dispatch(toggleLabelManager());
                setLabelPickerActive(false);
              }}
            >
              Edit Labels
              <ArrowSmallRightIcon className="edit-labels-arrow-icon" />
            </div>
          </div>
        )}

        {createLabelActive && (
          <CreateLabel
            updateLabel={(newValue) => {
              updateLabel(newValue);
              addRecentLabel(newValue);
            }}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setCreateLabelActive={setCreateLabelActive}
            createLabelActive={createLabelActive}
          />
        )}
      </div>
    </div>
  );
}

export const Label = ({
  label,
  labelOption,
  index,
  indexSelected,
  updateLabel,
  addRecentLabel,
  setLabelPickerActive,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && index === indexSelected) {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [indexSelected, ref]);

  return (
    <div
      ref={ref}
      key={labelOption.id + "label_option"}
      className={
        "label-selector-option" + (index === indexSelected ? " selected" : "")
      }
      onClick={() => {
        if (labelOption.id === label) {
          updateLabel(null);
        } else {
          addRecentLabel(labelOption.id);
          updateLabel(labelOption.id);
        }
        setLabelPickerActive(false);
      }}
    >
      <div
        className="label-box ph-mask"
        style={{ backgroundColor: labelOption.color }}
      />

      <span> {labelOption.name}</span>
    </div>
  );
};
