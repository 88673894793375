import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { FiPlus } from "react-icons/fi";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { IoWarning } from "react-icons/io5";
import { setNotionWorkspaceVisible } from "../../../redux/appSlice";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { platform } from "@todesktop/client-core";

export default function NotionWorkspaceSelection({
  onSelectWorkspace,
  backButtonClicked,
}) {
  const [notionWorkspaces, setNotionWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userNotionSettings = useSelector(
    (state) => state.app.currentUser?.notion
  );

  const notionWorkspaceVisible = useSelector(
    (state) => state.app.notionWorkspaceVisible
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // if notionWorkspaceVisible, select that one then set notionWorkspaceVisible to null
    if (notionWorkspaceVisible) {
      const workspace = notionWorkspaces.find(
        (workspace) => workspace.workspace_id === notionWorkspaceVisible
      );

      onSelectWorkspace(workspace);
      dispatch(setNotionWorkspaceVisible(null));
    }
  }, [notionWorkspaceVisible, notionWorkspaces]);

  useEffect(() => {
    if (userNotionSettings) {
      setIsLoading(true);
      // Simulate a short loading time to ensure smooth animation
      setNotionWorkspaces(Object.values(userNotionSettings));
      setIsLoading(false);
    }
  }, [userNotionSettings]);

  const handleLinkNewWorkspace = () => {
    if (isDesktopApp()) {
      platform.os.openURL(
        `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
      );
    } else {
      window.open(
        `https://api.notion.com/v1/oauth/authorize?client_id=870ea79c-0e00-4452-a0be-b753ec74e9f9&response_type=code&owner=user&redirect_uri=https%3A%2F%2Fapp.ellieplanner.com%2Fnotion-oauth`
      );
    }
  };

  const notionErrors = useSelector((state) => state.app.notion_errors);

  function getWorkspaceIcon(workspace) {
    if (workspace.workspace_icon) {
      if (workspace.workspace_icon.startsWith("http")) {
        return (
          <img
            className="h-10 w-10 rounded-md object-cover"
            src={workspace.workspace_icon}
            alt={`${workspace.workspace_name} logo`}
          />
        );
      } else {
        return (
          <div className="text-2xl h-10 w-10 rounded-md bg-neutral-100 dark:bg-neutral-800 flex items-center justify-center">
            {workspace.workspace_icon}
          </div>
        );
      }
    } else {
      return (
        <img
          className="h-10 w-10 rounded-md object-cover"
          src={require("../../../images/Notion_app_logo.png")}
          alt={`${workspace.workspace_name} logo`}
        />
      );
    }
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      <h2 className="text-xl font-semibold">Notion Workspaces</h2>

      {!isLoading ? (
        <div initial="hidden" animate="visible" exit="hidden">
          {notionWorkspaces.map((workspace) => (
            <div
              key={workspace.workspace_id}
              className="flex items-center justify-between p-4 border border-neutral-200 dark:border-neutral-700 rounded-lg cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-800 mb-2"
              onClick={() => onSelectWorkspace(workspace)}
            >
              <div className="flex items-center gap-2">
                {getWorkspaceIcon(workspace)}
                <div>
                  <div className="text-base font-medium">
                    {workspace.workspace_name}
                  </div>
                  <div className="text-xs text-neutral-500">
                    {workspace.owner?.user?.person?.email}
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-2 items-center">
                {notionErrors[workspace.workspace_id] && (
                  <div className="text-orange-700 dark:text-orange-300 flex flex-row gap-1 items-center bg-orange-100 dark:bg-orange-800/30 rounded-2xl px-4 py-2">
                    <IoWarning />
                    <div className="text-xs font-semibold">Sync Error</div>
                  </div>
                )}
                <ArrowRightCircleIcon className="h-6 w-6 text-neutral-400" />
              </div>
            </div>
          ))}

          <button
            className="mt-4 w-full flex items-center justify-center gap-2 text-white text-base px-4 py-2 bg-blue-600 rounded-full cursor-pointer hover:bg-blue-800"
            onClick={handleLinkNewWorkspace}
            layout
          >
            <FiPlus className="text-sm" />
            <span className="text-sm font-medium">Link New Workspace</span>
          </button>
        </div>
      ) : (
        <button
          className="mt-4 w-full flex items-center justify-center gap-2 text-white text-base px-4 py-2 bg-blue-600 rounded-full cursor-pointer hover:bg-blue-800"
          onClick={handleLinkNewWorkspace}
          layout
        >
          <FiPlus className="text-sm" />
          <span className="text-sm font-medium">Link New Workspace</span>
        </button>
      )}
    </div>
  );
}
