import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BrowserRouter } from "react-router-dom";
import Auth from "./Components/Auth";
import Toast from "./Components/Generics/Toast";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import posthog from "posthog-js";

import { ConfigProvider, theme } from "antd";
import { app_version, isDev } from "./utils";
import { Toaster } from "sonner";
import { isDesktopApp } from "@todesktop/client-core/platform/todesktop";
import { getVersion } from "@todesktop/client-core/app";

posthog.init("phc_P8jh9Y1lfEJx73BNm0EWIBBKI4YrBe7CBF5YZAHG9dS", {
  api_host: "https://gryffin.ellieplanner.com",
  session_recording: {
    maskTextSelector: ".ph-mask",
  },
  autocapture: false,
});

Sentry.init({
  dsn: "https://536adbe2e8be46bfa6d9e4d43828438f@o1131095.ingest.sentry.io/6175295",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay(),
    new posthog.SentryIntegration(posthog, "Ellie", 35841),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

const container = document.getElementById("root");
const root = createRoot(container);

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    // ...

    if (!isDev) {
      Sentry.setUser({ id: user.uid });
    }

    posthog.setPersonPropertiesForFlags({
      email: user.email,
    });

    posthog.identify(
      user.uid, // Replace 'distinct_id' with your user's unique identifier
      {
        email: user.email,
        app_version: isDesktopApp() ? getVersion() : app_version,
      } // optional: set additional user properties
    );

    root.render(
      <Provider store={store}>
        <App userId={uid} />
        <Toast />
      </Provider>
    );
  } else {
    // User is signed out
    // ...

    document.body.setAttribute("data-theme", defaultDark ? "dark" : "light");

    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              hashed: false,
              algorithm: defaultDark
                ? theme.darkAlgorithm
                : theme.lightAlgorithm,
            }}
          >
            <Auth />
            <Toaster position="top-center" closeButton />
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
