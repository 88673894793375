import React, { useEffect, useRef } from "react";
import { pushNotifications, nativeWindow } from "@todesktop/client-core";
import { useDispatch, useSelector } from "react-redux";
import { setCardModalActive } from "../../redux/appSlice";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { addHours, isAfter } from "date-fns";

function DueDateNotification() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.app.uid);
  const scheduledNotifications = useRef(new Map());

  const scheduleNotification = (task) => {
    const taskId = task.id;
    const dueDateNotificationTime = task.due_date_notification_time.toDate();
    const delay = dueDateNotificationTime.getTime() - new Date().getTime();

    if (delay > 0) {
 

      const timeoutId = setTimeout(async () => {
        new Notification("Task Due Soon", {
          body: task.description,
          data: {
            taskID: taskId,
          },
        }).onclick = () => {
          // Bring the main window to the front using ToDesktop
          nativeWindow.focus();

          dispatch(setCardModalActive(taskId));
        };

        // Remove from the map after notification is sent
        scheduledNotifications.current.delete(taskId);
      }, delay);

      // Store the timeout ID in the map
      scheduledNotifications.current.set(taskId, timeoutId);
    }
  };

  const fetchAndScheduleNotifications = () => {
    const now = new Date();
    const currentDatePlus24Hours = addHours(now, 24);

    const notificationToSendQuery = query(
      collection(db, "users", userId, "tasks"),
      where("due_date_notification_time", ">=", now),
      where("due_date_notification_time", "<=", currentDatePlus24Hours)
    );

    // Execute the query and set up a real-time listener
    const unsubscribe = onSnapshot(notificationToSendQuery, (querySnapshot) => {
     
      const tasks = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Clear all previously scheduled notifications
      scheduledNotifications.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
      scheduledNotifications.current.clear();

      // Rebuild the scheduled notifications list
      tasks.forEach((task) => {
        const dueDateNotificationTime =
          task.due_date_notification_time.toDate();
        if (isAfter(dueDateNotificationTime, now)) {
          scheduleNotification(task);
        }
      });
    });

    // Clean up the listener when the component is unmounted or re-run
    return unsubscribe;
  };

  useEffect(() => {
    // Initial fetch and schedule
    let unsubscribe = fetchAndScheduleNotifications();

    // Set interval to re-run the query every 24 hours
    const intervalId = setInterval(() => {
      // Unsubscribe the previous listener
      unsubscribe();
      // Fetch and schedule notifications again
      unsubscribe = fetchAndScheduleNotifications();
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

    // Clean up the listener and interval when the component is unmounted
    return () => {
      unsubscribe();
      clearInterval(intervalId);
      // Clear all scheduled notifications
      scheduledNotifications.current.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
      scheduledNotifications.current.clear();
    };
  }, [userId, dispatch]);

  return <></>;
}

export default DueDateNotification;
