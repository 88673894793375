import { IoClose } from "react-icons/io5";
import { MdOutlineAdd } from "react-icons/md";
import DynamicFilter from "./DynamicFilter";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import { convertNotionFilterToAvailableProperties } from "./NotionDatabaseItem";
import _, { set } from "lodash";

const DynamicQueryFilter = ({
  availableProperties,
  setFilters,
  disabled,
  notionUser,
  filters,
}) => {
  // Filters is in the format:
  /* {
    and: [
      {
        property: "title",
        select: { equals: "Test" },
        _propertyType: "select",
        _propertyName: "Title",
        _valueName: "Test",
        _valueId: "123",
      },
    ],
    ]}
    */

  const [usedProperties, setUsedProperties] = useState(new Set());

  // // Update the filter whenever available properties change
  // function cleanFilters(filters) {
  //   // Filters follow the same format as Notion API filters
  //   // Example: { and: [{ property: "title", select: { equals: "Test" } }] }
  //   // But with extra values of _propertyType _propertyName and _valueName // These are used for UI
  //   const notionFilter = {
  //     and:
  //       filters?.and &&
  //       filters?.and
  //         .map((filter) => {
  //           if (filter.selectedPropertyKey && filter.value !== null) {
  //             const prop = availableProperties.find(
  //               (p) => p.id === filter.selectedPropertyKey
  //             );
  //             switch (prop?.type) {
  //               case "checkbox":
  //                 return {
  //                   property: prop?.id,
  //                   checkbox: { equals: filter.value },
  //                   _propertyType: prop?.type,
  //                   _propertyName: prop?.name,
  //                   _valueName: filter.value ? true : false,
  //                 };
  //               case "select":
  //                 var valueName = prop?.select?.options.find(
  //                   (option) => option.id === filter.value
  //                 )?.name;

  //                 return {
  //                   property: prop?.id,
  //                   select: { equals: filter.value },
  //                   _propertyType: prop?.type,
  //                   _propertyName: prop?.name,
  //                   _valueName: valueName || filter.value,
  //                 };
  //               case "multi_select":
  //                 var valueName = prop?.multi_select?.options.find(
  //                   (option) => option.id === filter.value
  //                 )?.name;

  //                 return {
  //                   property: prop?.id,
  //                   multi_select: { contains: filter.value },
  //                   _propertyType: prop?.type,
  //                   _propertyName: prop?.name,
  //                   _valueName: valueName || filter.value,
  //                 };
  //               case "status":
  //                 var valueName = prop?.status?.options.find(
  //                   (option) => option.id === filter.value
  //                 )?.name;

  //                 return {
  //                   property: prop?.id,
  //                   status: { equals: filter.value },
  //                   _propertyType: prop?.type,
  //                   _propertyName: prop?.name,
  //                   _valueName: valueName || filter.value,
  //                 };
  //               case "people":
  //                 return {
  //                   property: prop?.id,
  //                   people: { contains: notionUser?.id },
  //                   _propertyType: prop?.type,
  //                   _propertyName: prop?.name,
  //                   _valueName: notionUser?.id,
  //                 };
  //               default:
  //                 return null;
  //             }
  //           }
  //           return null;
  //         })
  //         .filter(Boolean),
  //   };

  //   return notionFilter;
  // }

  // useEffect(() => {
  //   const notionFilter = cleanFilters(filters);

  //   onChange(notionFilter);
  // }, [filters, availableProperties]);

  const addFilter = () => {
    if (usedProperties.size >= availableProperties.length) {
      toast.error("All available properties are already in use");
    } else {
      // Add it to the "and" array of filters
      var currentFilters = _.cloneDeep(filters);

      if (currentFilters?.and && currentFilters?.and.length > 0) {
        // Add {} to the end of the array
        currentFilters.and.push({});
      } else {
        // Add the first filter
        currentFilters = {
          and: [{}],
        };
      }

      setFilters(currentFilters);
    }
  };

  const removeFilter = (index) => {
    const currentFilters = _.cloneDeep(filters);

   
    if (currentFilters?.and && currentFilters?.and.length > 0) {
      currentFilters.and.splice(index, 1);
    }

  

    setFilters(currentFilters);
  };

  const updateFilter = (index, filterData) => {
    const currentFilters = _.cloneDeep(filters);

    if (currentFilters?.and && currentFilters?.and.length > 0) {
      currentFilters.and[index] = filterData;
    }

    setFilters(currentFilters);
  };



  function isValidFilter(filter) {
    // We should check the filter against the available properties
    // If the property is not available, return { valid: false, message: "Property no longer available" }
    // If the property type is no longer the same, return { valid: false, message: "Property type changed" }

    // If the property is available, return { valid: true }

    if (!filter.property) {
      return { valid: true };
    }

    const prop = availableProperties.find((p) => p.id === filter.property);

    if (!prop) {
      return {
        valid: false,
        message: "Property no longer available, please delete or change",
      };
    }

    if (prop.type !== filter._propertyType) {
      return {
        valid: false,
        message: "Property type changed, please delete or change",
      };
    }

    return { valid: true };
  }

  return (
    <>
      {availableProperties.length === 0 ? (
        <div>No available filters</div>
      ) : (
        <div className="flex flex-col gap-4 items-start w-full mx-[-2px]">
          {filters &&
            filters?.and &&
            Object.values(filters?.and).map((filter, index) => {
              const validationResult = isValidFilter(filter);
              return (
                <div
                  key={index}
                  className={`w-full ${
                    !validationResult.valid
                      ? "border border-solid border-red-100 dark:border-red-800 rounded-lg py-2.5 pb-4 px-2"
                      : "px-2"
                  }`}
                >
                  {!validationResult.valid && (
                    <div className="text-red-500 text-sm mb-3">
                      {validationResult.message}
                    </div>
                  )}
                  <div className={`flex flex-row items-center gap-4 w-full `}>
                    <div className="w-12 text-xs font-medium text-gray-600">
                      {index === 0 ? "WHERE" : "AND"}
                    </div>
                    <div className="flex-grow">
                      <DynamicFilter
                        availableProperties={availableProperties.filter(
                          (prop) =>
                            !usedProperties.has(prop.id) ||
                            prop.id === filter.selectedPropertyKey
                        )}
                        onChange={(updatedFilter) => {
                          updateFilter(index, updatedFilter);
                        }}
                        disabled={disabled}
                        filter={filter}
                        notionUserId={notionUser?.id}
                      />
                    </div>
                    <IoClose
                      className="text-neutral-400 cursor-pointer w-3"
                      onClick={() => removeFilter(index)}
                    />
                  </div>
                </div>
              );
            })}
          <div
            className="flex items-center gap-1 text-blue-600 cursor-pointer text-sm hover:text-blue-800"
            onClick={addFilter}
          >
            <MdOutlineAdd className="font-semibold" />
            <span>Add filter</span>
          </div>
        </div>
      )}
    </>
  );
};

export default DynamicQueryFilter;
