// calendarAccountsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { auth } from "../firebase";
import { googleServerUrl, outlookServerUrl } from "../utils";
import { openDB } from "idb";

const initialState = {
  googleCalendars: {},
  appleCalendars: {},
  outlookCalendars: {},
  loading: false,
  loadedOnce: false,
  error: null,
};

const DB_NAME = "calendarAccountsDB";
const STORE_NAME = "calendarAccounts";

const openCalendarAccountsDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME);
    },
  });
};

const getCalendarAccountsFromIndexedDB = createAsyncThunk(
  "calendarAccounts/getFromIndexedDB",
  async (_, { rejectWithValue }) => {
    try {
      const db = await openCalendarAccountsDB();
      const tx = db.transaction(STORE_NAME, "readonly");
      const store = tx.objectStore(STORE_NAME);
      const accounts = {
        googleCalendars: await store.get("googleCalendars"),
        appleCalendars: await store.get("appleCalendars"),
        outlookCalendars: await store.get("outlookCalendars"),
      };
      await tx.done;
      return accounts;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const saveCalendarAccountsToIndexedDB = async (accounts) => {
  const db = await openCalendarAccountsDB();
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  await store.put(accounts.googleCalendars, "googleCalendars");
  await store.put(accounts.appleCalendars, "appleCalendars");
  await store.put(accounts.outlookCalendars, "outlookCalendars");
  await tx.done;
};

export const fetchGoogleCalendars = createAsyncThunk(
  "calendarAccounts/fetchGoogleCalendars",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${googleServerUrl}/getCalendars`, {
        params: { userId },
      });
      return response.data.calendars;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAppleCalendars = createAsyncThunk(
  "calendarAccounts/fetchAppleCalendars",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${googleServerUrl}/getAppleCalendars`, {
        params: { userId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOutlookCalendars = createAsyncThunk(
  "calendarAccounts/fetchOutlookCalendars",
  async (_, { rejectWithValue }) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await axios.get(`${outlookServerUrl}/getOutlookCalendars`, {
        params: { idToken },
        headers: { "ngrok-skip-browser-warning": "true" },
      });
      return response.data.calendars;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllCalendarAccounts = createAsyncThunk(
  "calendarAccounts/fetchAllCalendarAccounts",
  async (_, { dispatch, rejectWithValue, getState }) => {
    await dispatch(getCalendarAccountsFromIndexedDB());

    const userId = getState().app.uid;
    const [google, apple, outlook] = await Promise.all([
      dispatch(fetchGoogleCalendars(userId)),
      dispatch(fetchAppleCalendars(userId)),
      dispatch(fetchOutlookCalendars()),
    ]);

   console.log("FETCHING ALL CALENDAR ACCOUNTS", google, apple, outlook);

    const accounts = {
      googleCalendars: typeof google.payload === 'object' ? google.payload : {},
      appleCalendars: typeof apple.payload === 'object' ? apple.payload : {},
      outlookCalendars: typeof outlook.payload === 'object' ? outlook.payload : {},
    };

    if (!google.payload || !apple.payload || !outlook.payload) {
      return rejectWithValue('Failed to fetch one or more calendar accounts');
    }

    await saveCalendarAccountsToIndexedDB(accounts);
    return accounts;
  }
);

const calendarAccountsSlice = createSlice({
  name: "calendarAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCalendarAccountsFromIndexedDB.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false };
      })
      .addCase(fetchAllCalendarAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCalendarAccounts.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: false, error: null, loadedOnce: true };
      })
      .addCase(fetchAllCalendarAccounts.rejected, (state, action) => {
        state.loading = false;
        state.loadedOnce = true
        state.error = action.payload;
      });
  },
});

export default calendarAccountsSlice.reducer;