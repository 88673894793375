import axios from "axios";
import React, { useEffect } from "react";
import { analytics, notionServerUrl } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import {
  setNotionWorkspaceVisible,
  setSettingsPageVisible,
  toggleSettings,
} from "../../redux/appSlice";

function NotionAuth() {
  const userId = useSelector((state) => state.app.uid);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleNotionAuth = async () => {
      if (window.location.pathname.includes("/notion-oauth")) {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (code) {
          const toastId = toast.loading("Linking Notion workspace...");

          try {
            const response = await axios.get(
              `${notionServerUrl}/getAccessToken?code=${code}&userId=${userId}`
            );

            if (response.data) {
              analytics("Notion workspace linked");
              toast.success("Notion workspace linked successfully", {
                id: toastId,
              });

              console.log("response.data", response.data);
              const workspaceId = response.data?.workspace_id;

              if (workspaceId) {
                dispatch(toggleSettings());
                dispatch(setSettingsPageVisible("integrations"));
                dispatch(setNotionWorkspaceVisible(workspaceId));

                // Let's also change the url to just be the home
                window.history.pushState({}, document.title, "/");
              }
            }
          } catch (error) {
            console.error("Error linking Notion workspace:", error);
            analytics("Notion workspace link failed");
            toast.error(
              "Error linking Notion workspace. Please contact support",
              { id: toastId }
            );
          }
        }
      }
    };

    handleNotionAuth();
  }, [userId, dispatch]);

  return null;
}

export default NotionAuth;
